// @ts-nocheck
import React from 'react'
import { Text, TextProps } from 'rebass'
import styled from 'styled-components'
import { Colors } from '../../theme/styled'

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme.colors as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'textSecondary'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'textPrimary'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'textPrimary'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'textSecondary'} {...props} />
  },
  info(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} color={'textTertiary'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'black'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'error' : 'textFall'} {...props} />
  }
}
