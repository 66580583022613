import React from 'react'
import styled from 'styled-components'
import { Trans } from '@lingui/macro'
// import { DEFAULT_LOCALE, SupportedLocale } from '../../constants/locales'
import { useActiveLocale } from '../../hooks/useActiveLocale'

const Nav: React.FC = () => {
  const activeLocale = useActiveLocale()
  // let targetLocale: SupportedLocale
  if (activeLocale) {
    // targetLocale = activeLocale
  } else {
    // targetLocale = DEFAULT_LOCALE
  }

  return (
    <StyledNav>
      {/* <StyledAbsoluteLink href={`https://zestswap.org/${targetLocale}`}>
        <Trans>Mix</Trans>
      </StyledAbsoluteLink> */}
      <StyledAbsoluteLink href="/#/swap" className="active">
        <Trans>Swap</Trans>
      </StyledAbsoluteLink>
      {/* <StyledAbsoluteLink href={`https://zestswap.org/${targetLocale}/farms`}>
          <Trans>Farms</Trans>
      </StyledAbsoluteLink> */}
      {/* <StyledAbsoluteLink href={`https://zestswap.org/${targetLocale}/trading`}>
          <Trans>Trading</Trans>
      </StyledAbsoluteLink> */}
      {/* <StyledAbsoluteLink href={`https://zestswap.org/${targetLocale}/liquidity`}>
          <Trans>Pool</Trans>
      </StyledAbsoluteLink> */}
      {/* <StyledAbsoluteLink href={`https://zestswap.org/${targetLocale}/farms`}>
        <Trans>Farms</Trans>
      </StyledAbsoluteLink>
      <StyledAbsoluteLink href={`https://zestswap.org/${targetLocale}/trading`}>
        <Trans>Trading</Trans>
      </StyledAbsoluteLink>
      <StyledAbsoluteLink href={`https://zestswap.org/${targetLocale}/liquidity`}>
        <Trans>Pool</Trans>
      </StyledAbsoluteLink> */}
      {/* <StyledAbsoluteLink href={`https://zestswap.org/${targetLocale}/dao`}>
        <Trans>Dao</Trans>
      </StyledAbsoluteLink> */}
      {/* <StyledAbsoluteLink href={`https://zestswap.org/${targetLocale}/burn`}>
        <Trans>Burn</Trans>
      </StyledAbsoluteLink> */}
      {/* <StyledAbsoluteLink href="https://info-oec.cocoswap.com" target="_blank">
        <Trans>Info</Trans>
      </StyledAbsoluteLink> */}
      <StyledAbsoluteDisabledLink>
        <StyledAbsoluteDisabledText>
          <Trans>Farms</Trans>
        </StyledAbsoluteDisabledText>
      </StyledAbsoluteDisabledLink>
      <StyledAbsoluteDisabledLink>
        <StyledAbsoluteDisabledText>
          <Trans>Pool</Trans>
        </StyledAbsoluteDisabledText>
      </StyledAbsoluteDisabledLink>
      <StyledAbsoluteDisabledLink>
        <StyledAbsoluteDisabledText>
          <Trans>Info</Trans>
        </StyledAbsoluteDisabledText>
      </StyledAbsoluteDisabledLink>
      {/* <StyledAbsoluteDisabledLink>
        <StyledAbsoluteDisabledText>
          <Trans>bridge</Trans>
        </StyledAbsoluteDisabledText>
      </StyledAbsoluteDisabledLink> */}
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  font-family: Kanit, Roboto;
`

const StyledAbsoluteLink = styled.a`
  font-family: Kanit, Roboto;
  position: relative;
  margin-right: 32px;
  text-decoration: none;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.textSecondary};
  &:hover {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
  &.active {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
  @media (max-width: 400px) {
    margin-right: 32px;
  }
`

const StyledAbsoluteDisabledLink = styled.span`
  font-family: Kanit, Roboto;
  position: relative;
  margin-right: 32px;
  text-decoration: none;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.textSecondary};
  @media (max-width: 400px) {
    margin-right: 32px;
  }
  cursor: not-allowed;
`
const StyledAbsoluteDisabledText = styled.span`
  opacity: 0.4;
`

// const StyledAbsoluteDisabledInfo = styled.em`
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 15px;
//   transform: scale(0.83) translate(-9px, -100%);
//   padding: 3px 10px;
//   border-radius: 8px;
//   background-color: ${({ theme }) => theme.colors.error};
//   color: ${({ theme }) => theme.colors.bg};
//   position: absolute;
//   font-weight: 400;
//   font-style: none;
//   white-space: nowrap;
//   left: 0;
//   top: 0;
//   z-index: 1;
// `

export default Nav
