import React from 'react'
import styled from 'styled-components'
import useCopyClipboard from '../../hooks/useCopyClipboard'

import { LinkStyledButton } from '../Shared'
import { CheckCircle, Copy } from 'react-feather'

const CopyIcon = styled(LinkStyledButton)`
  color: ${({ theme }) => theme.colors.textSecondary};
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  padding: 10px 20px;
  border-radius: 12px;
  flex-shrink: 0;
  display: flex;
  flex: 1;
  height: 36px;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  text-decoration: none!important;
  font-size: 14px;
  line-height: 20px;
  :hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textTheme};
  }
  div {
    text-decoration: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: block;
    width: auto;
    height: 56px;
    padding: 8px 0;
    font-size: 12px;
    line-height: 16px;
    box-sizing: border-box;
    :hover,
    :active,
    :focus {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.textSecondary};
      background-color: ${({ theme }) => theme.colors.bgTertiary};
    }
    > span{
      display: block;
      margin: 0 auto;
    }
    > div{
      display: block !important;
      margin: 0 !important;
    }
  `};
`
const TransactionStatusText = styled.span`
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
`

export default function CopyHelper(props: { toCopy: string; children?: React.ReactNode }) {
  const [isCopied, setCopied] = useCopyClipboard()

  return (
    <CopyIcon onClick={() => setCopied(props.toCopy)}>
      {isCopied ? (
        <TransactionStatusText>
          <CheckCircle size={'20'} />
          <TransactionStatusText style={{ marginLeft: '8px' }}>Copied</TransactionStatusText>
        </TransactionStatusText>
      ) : (
        <TransactionStatusText>
          <Copy size={'20'} />
        </TransactionStatusText>
      )}
      {isCopied ? '' : props.children}
    </CopyIcon>
  )
}
