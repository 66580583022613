import React from 'react'
import { SettingTabs } from '../../components/NavigationTabs'
import SettingsTab from '../../components/Settings'
import AppBody from '../AppBody'

export default function PoolFinder() {
  return (
    <AppBody>
      <SettingTabs />
      <SettingsTab isShow={true} />
    </AppBody>
  )
}
