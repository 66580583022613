import React, { useRef } from 'react'
import styled from 'styled-components'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import useToggle from '../../hooks/useToggle'
import { IconFont } from '../IconFont'
import okbImg from '../../assets/images/okbchain.svg'

const ImgWrapper = styled.img`
  display: block;
  width: 20px;
  height: 20px;
`

const StyledMenuButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 20px;
  color: ${({ theme }) => theme.colors.primary1};
  border-radius: 0.5rem;
  cursor: pointer;
  .active {
    margin-left: 8px!important;
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 0.2rem;

    > * {
      stroke: ${({ theme }) => theme.colors.primary1};
    }
  }

  @media screen and (max-width: 480px) {
    svg {
      display: none!important;
    }
    .none, .anticon  {
      display: none!important;
    }
  }
`

const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.div`
  position: absolute;
  top: 3rem;
  left: 0rem;
  z-index: 100;
  display: flex;
  border-radius: 16px;
  padding: 8px 4px 4px;
  flex-direction: column;
  box-shadow: 0px 8px 16px 0px rgba(127, 137, 163, 0.16);
  background-color: ${({ theme }) => theme.colors.bg};
  transform: translateX(-20px);
`

const MenuItemLink = styled.div`
  display: flex;
  align-items: center;
  width: 160px;
  height: 36px;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 12px;
  line-height: 20px;
  margin-bottom: 4px;
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.textPrimary};
  text-decoration: none;

  &:not(.disabled).active,
  &:not(.disabled):focus,
  &:not(.disabled):hover {
    color: ${({ theme }) => theme.colors.textTheme};
    background: ${({ theme }) => theme.colors.bgHover};
  }

  &.disabled {
    opacity: .4;
    cursor: not-allowed;
  }
`

const LangIcon = styled(IconFont)`
  height: 20px;
  width: 20px;
  line-height: 24px;
  color: ${props => props.theme.colors.buttonText};
  cursor: pointer;
`

const Button = styled.div`
  width: 80px;
  font-size: 14px;
  text-align: center;
  border-radius: 8px;
  font-weight: bolder;
  color: ${({ theme }) => theme.colors.buttonText};
  &.unactive {
    color: rgba(101, 122, 163, 0.6);
  }
`

export default function Menu() {
  const node = useRef<HTMLDivElement>()
  const [open, toggle] = useToggle(false)

  useOnClickOutside(node, open ? toggle : undefined)

  return (
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <ImgWrapper src={okbImg} />
        {/* <ImgWrapper src={okbImg} /> */}

        <Button className={'active none'}>X Layer</Button>
        <LangIcon type="icon-arrow_down" className="none" />
      </StyledMenuButton>
      {open && (
        <MenuFlyout>
          <MenuItemLink
            // onClick={() => {
            //   window.location.href = 'https://swapalpha.zestswap.org'
            // }}
            className="active"
          >
            <ImgWrapper src={okbImg} style={{ marginRight: '8px' }} />
            X Layer
          </MenuItemLink>
          {/* <MenuItemLink
            onClick={() => {
              window.location.href = '/'
            }}
            className="disabled"
          >
            <ImgWrapper src={oktImg} style={{ marginRight: '8px' }} />
            X Layer
          </MenuItemLink> */}
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
