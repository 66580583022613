import { useState } from 'react'
// import { Contract } from 'ethers'
import { TokenAmount } from '@pancakeswap-libs/sdk'
// import { BigNumber } from '@ethersproject/bignumber'
// import { useTradingContract } from './useContract'
// import { useActiveWeb3React } from './index'
// import { useRefresh } from '../hooks/useBlock'
// import { ZEST } from '../constants/index'

export interface RewardsProps {
  totalRewards?: TokenAmount
}

// const getBalanceNumber = (balance: BigNumber, currency: Token) => {
//   return new TokenAmount(currency, balance.toString())
// }
// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function useTotalReward(): RewardsProps | undefined {
  const [Rewards] = useState<RewardsProps | undefined>()
  // const { account } = useActiveWeb3React()
  // const contract = useTradingContract(true)
  // const { slowRefresh } = useRefresh()
  // const fetchReward = useCallback(async () => {
  //   async function getReward(contract: Contract, userAddress: string): Promise<RewardsProps | undefined> {
  //     let rewards = BigNumber.from(0)
  //     try {
  //       const result = []
  //       const poolLength = await contract.poolLength()
  //       for (let i = 0; i < poolLength.toNumber(); i++) {
  //         const data = {
  //           totalRewards: BigNumber.from(0)
  //         }
  //         const userInfo = await contract.getUserReward(i, { from: userAddress })
  //         data.totalRewards = userInfo[0]
  //         result.push(data)
  //       }
  //       result.forEach(v => {
  //         rewards = v.totalRewards.add(rewards)
  //       })
  //       const totalRewards = getBalanceNumber(rewards, ZEST)
  //       return { totalRewards }
  //     } catch (error) {
  //       console.log(error)
  //       return {}
  //     }
  //   }
  //   if (contract && account) {
  //     const rewards = await getReward(contract, account)
  //     setRewards(rewards)
  //   }
  // }, [contract, account])

  // useEffect(() => {
  //   if (account && contract) {
  //     // fetchReward()
  //   }
  // }, [account, setRewards, contract, slowRefresh])

  return Rewards
}
