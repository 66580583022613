import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import FooterModal from './FooterModal'
import { Trans } from '@lingui/macro'
import { judgeBigScreen } from '../../utils/tools'
// import { DEFAULT_LOCALE, SupportedLocale } from '../../constants/locales'
import { useActiveLocale } from '../../hooks/useActiveLocale'

const MobileFooter = styled(({ bigScreen, ...rest }) => <div {...rest} />)`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  box-sizing: border-box;
  padding: 6px 8px;
  justify-content: space-between;
  background: ${({theme}) => theme.colors.bgSecondary};
  border-top 1px solid rgba(131, 134, 143, 0.16);
  padding-bottom: ${({ bigScreen }) => (bigScreen ? `32px` : '6px')};
`

const FooterItem = styled.a`
  display: block;
  width: 72px;
  height: 100%;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textTertiary} 
  &.active {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
  &.disabled {
    opacity: .4;
  }
`

const ItemLabel = styled.div`
  margin-top: 3px;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
`

const ItemIcon = styled.div`
  width: 20px;
  height: 20px;
  margin: 0 auto;
  background-size: 192px auto;
  background-image: url('/footerIcon.png');
`

export default function Footer() {
  const activeLocale = useActiveLocale()
  // let targetLocale: SupportedLocale
  if (activeLocale) {
    // targetLocale = activeLocale
  } else {
    // targetLocale = DEFAULT_LOCALE
  }
  const [footermodal, setFooterModal] = useState(false)
  // const handlePresentFooterModal = useCallback(() => {
  //   setFooterModal(true)
  // }, [setFooterModal])
  const handleDismissFooterModal = useCallback(() => {
    setFooterModal(false)
  }, [setFooterModal])

  let acitve = 'swap'

  return (
    <MobileFooter bigScreen={judgeBigScreen()}>
      {/* <FooterItem href={`https://alpha.zestswap.org/${targetLocale}`} className="active">
        <ItemIcon style={{ backgroundPosition: acitve === 'mix' ? '-2px -26px' : '-2px -2px' }}></ItemIcon>
        <ItemLabel>
          <Trans>Mix</Trans>
        </ItemLabel>
      </FooterItem> */}
      <FooterItem href="/#/swap" className="active">
        <ItemIcon style={{ backgroundPosition: acitve === 'swap' ? '-26px -26px' : '-26px -2px' }}></ItemIcon>
        <ItemLabel>
          <Trans>Swap</Trans>
        </ItemLabel>
      </FooterItem>
      <FooterItem className='disabled'>
        <ItemIcon style={{ backgroundPosition: acitve === 'farms' ? '-50px -26px' : '-50px -2px' }}></ItemIcon>
        <ItemLabel>
          <Trans>Farms</Trans>
        </ItemLabel>
      </FooterItem>
      <FooterItem className='disabled'>
        <ItemIcon style={{ backgroundPosition: acitve === 'nft' ? '-98px -26px' : '-98px -2px' }}></ItemIcon>
        <ItemLabel>
          <Trans>Pool</Trans>
        </ItemLabel>
      </FooterItem>
      <FooterItem className="disabled">
        <ItemIcon style={{ backgroundPosition: acitve === 'nft' ? '-122px -26px' : '-122px -2px' }}></ItemIcon>
        <ItemLabel>
          <Trans>Info</Trans>
        </ItemLabel>
      </FooterItem>
      {/* <FooterItem onClick={handlePresentFooterModal}>
        <ItemIcon style={{ backgroundPosition: '-98px -2px' }}></ItemIcon>
        <ItemLabel>
          <Trans>more</Trans>
        </ItemLabel>
      </FooterItem> */}

      <FooterModal onDismiss={handleDismissFooterModal} visible={footermodal} />
    </MobileFooter>
  )
}
