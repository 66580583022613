import React from 'react'
import styled from 'styled-components'
import logoText from '../../../assets/images/logo.png'
import singleLogoText from '../../../assets/images/single.png'

const Logo: React.FC = () => {
  return (
    <StyledLogo></StyledLogo>
  )
}

const StyledLogo = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 28px;
  margin-right: 42px;
  text-decoration: none;
  width: 224px;
  background-image: url(${logoText});
  background-size: auto 100%;
  background-repeat: no-repeat;
  // img {
  //   height: 28px;
  // }
  .text {
    margin-left: 6px;
    height: 23px;
    width: auto;
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    background-image: url(${singleLogoText});
    width: 40px;
    .text {
      display: none
    }
  `};
`

export default Logo
