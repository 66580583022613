import React from 'react'
import styled from 'styled-components'
import { darken, lighten } from 'polished'

import { RowBetween } from '../Row'
import { IconFont } from '../IconFont'
import { Button as RebassButton, ButtonProps } from 'rebass/styled-components'

const Base = styled(RebassButton) <{
  padding?: string
  width?: string
  borderRadius?: string
  altDisabledStyle?: boolean
  size?: string
}>`
  padding: ${({ padding }) => (padding ? padding : '12px')};
  width: ${({ width }) => (width ? width : '100%')};
  font-weight: 500;
  text-align: center;
  border-radius: 16px;
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  outline: none;
  border: 1px solid transparent;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }
`

export const ButtonPrimary = styled(Base)`
  padding: ${({ size }) => size ?? '0.75rem'};
  background: ${({ theme }) => theme.colors.primary1};
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  &:hover {
    background: ${({ theme }) => theme.colors.primary1};
  }
  &:disabled {
    background: ${({ theme, altDisabledStyle }) =>
    altDisabledStyle ? theme.colors.primary1 : theme.colors.bgTertiary};
    color: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? 'white' : theme.colors.textPrimary)};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.5' : '0.5')};
  }
`

export const ButtonLight = styled(Base)`
  background: ${({ theme }) => theme.colors.primary1};
  color: ${({ theme }) => theme.colors.buttonText};
  font-size: 16px;
  font-weight: 500;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.03, theme.colors.bgSecondary)};
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.buttonHover};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.bgSecondary};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.bgSecondary)};
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.buttonHover};
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.colors.primary1};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

export const ButtonGray = styled(Base)`
  background-color: ${({ theme }) => theme.colors.bg};
  color: ${({ theme }) => theme.colors.primary1};
  font-size: 16px;
  font-weight: 500;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.bg)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.bg)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.bg)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.1, theme.colors.bg)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.colors.bg)};
  }
`

export const ButtonSecondary = styled(Base)`
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  color: ${({ theme }) => theme.colors.textTheme};
  font-size: 16px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: ${({ padding }) => (padding ? padding : '10px')};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.colors.bgTertiary};
    background-color: ${({ theme }) => theme.colors.bgTertiary};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.bgHover1};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.colors.bgTertiary};
    background-color: ${({ theme }) => theme.colors.bgTertiary};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.bgTertiary};
    opacity: 40%;
    cursor: auto;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    :hover,
    :active.
    :focus{
      background-color: ${({ theme }) => theme.colors.bgTertiary};
      color: ${({ theme }) => theme.colors.textTheme};
    }
    
  `};
`

export const ButtonPink = styled(Base)`
  background-color: ${({ theme }) => theme.colors.primary1};
  color: white;

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.colors.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.colors.primary1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.colors.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.colors.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.colors.primary1)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.primary1};
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonOutlined = styled(Base)`
  border: none;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  color: ${({ theme }) => theme.colors.textPrimary};

  // &:focus {
  //   box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.textTheme};
  // }
  // &:hover {
  //   box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.textTheme};
  // }
  // &:active {
  //   box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.textTheme};
  // }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonEmpty = styled(Base)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary1};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    background-color: ${({ theme }) => theme.colors.advancedBG};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.advancedBG};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.advancedBG};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonWhite = styled(Base)`
  border: 1px solid #edeef2;
  background-color: ${({ theme }) => theme.colors.bg};
  color: black;

  &:focus {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    box-shadow: 0 0 0 1pt ${darken(0.05, '#edeef2')};
  }
  &:hover {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonConfirmedStyle = styled(Base)`
  background-color: ${({ theme }) => lighten(0.5, theme.colors.bgTheme)};
  color: ${({ theme }) => theme.colors.bg};
  border: 1px solid ${({ theme }) => theme.colors.bgTheme};

  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonErrorStyle = styled(Base)`
  background-color: ${({ theme }) => theme.colors.error};
  border: 1px solid ${({ theme }) => theme.colors.error};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.colors.error)};
    background-color: ${({ theme }) => darken(0.05, theme.colors.error)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.colors.error)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.colors.error)};
    background-color: ${({ theme }) => darken(0.1, theme.colors.error)};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.error};
    border: 1px solid ${({ theme }) => theme.colors.error};
  }
`

const SanJiao = styled.div`
  > * {
    svg {
      height: 24px;
      width: 24px;
      color: ${({ theme }) => theme.colors.textTertiary};
    }
  }
`

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
  }
}

export function ButtonError({
  error,
  altDisabledStyle,
  ...rest
}: { error?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
  }
}

export function ButtonDropdown({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <IconFont size={24} type="icon-arrow_down" />
      </RowBetween>
    </ButtonPrimary>
  )
}

export function ButtonDropdownLight({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonOutlined {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <SanJiao>
          <IconFont size={24} type="icon-arrow_down" />
        </SanJiao>
      </RowBetween>
    </ButtonOutlined>
  )
}

export function ButtonRadio({ active, ...rest }: { active?: boolean } & ButtonProps) {
  if (!active) {
    return <ButtonWhite {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}
