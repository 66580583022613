import React from 'react'
import styled from 'styled-components'

const ToggleElement = styled.span<{ isActive?: boolean; isOnSwitch?: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: ${({ isActive }) => (isActive ? '#fff' : 'transparent')};
`

const StyledToggle = styled.button<{ isActive?: boolean; activeElement?: boolean }>`
  width: 36px;
  height: 20px;
  padding: 2px;
  border-radius: 16px;
  box-sizing: border-box;
  border: 0;
  display: flex;
  cursor: pointer;
  outline: none;
  background: ${({ theme, isActive }) => (isActive ? theme.colors.textRise : theme.colors.separator)};
`

export interface ToggleProps {
  id?: string
  isActive: boolean
  toggle: () => void
}

export default function Toggle({ id, isActive, toggle }: ToggleProps) {
  return (
    <StyledToggle id={id} isActive={isActive} onClick={toggle}>
      <ToggleElement isActive={isActive} isOnSwitch={true}></ToggleElement>
      <ToggleElement isActive={!isActive} isOnSwitch={false}></ToggleElement>
    </StyledToggle>
  )
}
