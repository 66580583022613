import React, { useRef, useState, ReactNode } from 'react'
import styled from 'styled-components'
import { X } from 'react-feather'
import { isMobile } from 'react-device-detect'
import {
  useUserSlippageTolerance,
  useExpertModeManager,
  useUserDeadline,
  useUserSingleHopOnly
} from '../../state/user/hooks'
import TransactionSettings from '../TransactionSettings'
import Row, { RowFixed, RowBetween } from '../Row'
import { TYPE } from '../Shared'
import { CloseIcon } from '../Shared/Common'
import Toggle from '../Toggle'
import { AutoColumn } from '../Column'
import { ButtonError } from '../Button'
import { useSettingsMenuOpen, useToggleSettingsMenu, useToggleSettingsMenuPage } from '../../state/application/hooks'
import { Text } from 'rebass'
import Modal from '../Modal'
import { Trans } from '@lingui/macro'
import { IconFont } from '../IconFont'

const StyledMenuIcon = styled(IconFont)`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: transparent;
  > * {
    marging: 0;
    height: 24px;
    width: 24px;
    color: ${({ theme }) => theme.colors.textSecondary};
  }
  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.colors.inputBg};
  }
  :hover > * {
    color: ${({ theme }) => theme.colors.btnText};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    background-color: ${({ theme }) => theme.colors.inputBg};
  `};
`

const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({ theme }) => theme.colors.primary1};
  }
  flex: auto;
  text-align: right;
`

const StyledMenuButton = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border: none;
  margin: 0;
`
const EmojiWrapper = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  font-size: 14px;
`

const CloseWrapper = styled.div`
  flex: 1;
  justify-content: flex-end;
  text-align: right;
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  border-radius: 20px;
`

const Line = styled.div`
  width: 332px;
  height: 1px;
  margin: 12px auto 0;
  background: ${({ theme }) => theme.colors.separator};
`

export function SettingButton() {
  const toggle = useToggleSettingsMenu()
  const [expertMode] = useExpertModeManager()

  //const open = useSettingsMenuPage()
  const pcToggle = useToggleSettingsMenuPage()
  return (
    <>
      {!Boolean(isMobile) ? (
        <StyledMenuButton onClick={pcToggle} id="open-settings-dialog-button">
          <StyledMenuIcon type="icon-setting" />
          {expertMode && (
            <EmojiWrapper>
              <span role="img" aria-label="wizard-icon">
                🧙
              </span>
            </EmojiWrapper>
          )}
        </StyledMenuButton>
      ) : (
        <StyledMenuButton onClick={toggle} id="open-settings-dialog-button">
          <StyledMenuIcon type="icon-setting" />
          {expertMode && (
            <EmojiWrapper>
              <span role="img" aria-label="wizard-icon">
                🧙
              </span>
            </EmojiWrapper>
          )}
        </StyledMenuButton>
      )}
    </>
  )
}

export default function SettingsTab({ isShow = false }: { isShow: boolean }) {
  const node = useRef<HTMLDivElement>()
  const open = useSettingsMenuOpen()
  const toggle = useToggleSettingsMenu()

  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()

  const [deadline, setDeadline] = useUserDeadline()

  const [expertMode, toggleExpertMode] = useExpertModeManager()
  const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()

  // show confirmation view before turning on
  const [showConfirmation, setShowConfirmation] = useState(false)

  // useOnClickOutside(node, open ? toggle : undefined)

  const modalBottom = () => {
    return (
      <>
        <AutoColumn gap="md">
          {isMobile ? (
            <RowBetween>
              <Text fontWeight={500} fontSize={16}>
                Transaction Settings
              </Text>
              <CloseIcon onClick={toggle} />
            </RowBetween>
          ) : null}
          <TransactionSettings
            rawSlippage={userSlippageTolerance}
            setRawSlippage={setUserslippageTolerance}
            deadline={deadline}
            setDeadline={setDeadline}
          />
          <Line></Line>
          <RowBetween style={{ marginTop: '12px' }}>
            <RowFixed>
              <TYPE.black fontWeight={400} fontSize={14}>
                <Trans>Toggle Expert Mode</Trans>
              </TYPE.black>
            </RowFixed>
            <Toggle
              id="toggle-expert-mode-button"
              isActive={expertMode}
              toggle={
                expertMode
                  ? () => {
                      toggleExpertMode()
                      setShowConfirmation(false)
                    }
                  : () => {
                      setShowConfirmation(true)
                    }
              }
            />
          </RowBetween>
          <RowBetween style={{ marginTop: '12px' }}>
            <RowFixed>
              <TYPE.black fontWeight={400} fontSize={14}>
                <Trans>Disable Multihops</Trans>
              </TYPE.black>
            </RowFixed>
            <Toggle
              id="toggle-disable-multihop-button"
              isActive={singleHopOnly}
              toggle={() => setSingleHopOnly(singleHopOnly)}
            />
          </RowBetween>
        </AutoColumn>
      </>
    )
  }

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <Modal isOpen={showConfirmation} onDismiss={() => setShowConfirmation(false)} maxHeight={100}>
        <ModalContentWrapper>
          <AutoColumn gap="lg">
            <Row>
              <Text fontWeight={500} fontSize={20}>
                Are you sure?
              </Text>
              <CloseWrapper>
                <StyledCloseIcon onClick={() => setShowConfirmation(false)} />
              </CloseWrapper>
            </Row>
            <AutoColumn gap="lg">
              <TYPE.error error={true} fontSize={14}>
                Expert mode turns off the confirm transaction prompt and allows high slippage trades that often result
                in bad rates and lost funds.
              </TYPE.error>
              <Text fontWeight={400} fontSize={14}>
                Only use this mode if you know what you’re doing.
              </Text>
              <ButtonError
                padding={'12px'}
                onClick={() => {
                  if (window.prompt(`Please type the word "confirm" to enable expert mode.`) === 'confirm') {
                    toggleExpertMode()
                    setShowConfirmation(false)
                  }
                }}
              >
                <Text fontSize={16} fontWeight={500} id="confirm-expert-mode">
                  Turn On Expert Mode
                </Text>
              </ButtonError>
            </AutoColumn>
          </AutoColumn>
        </ModalContentWrapper>
      </Modal>
      {!isMobile && isShow ? modalBottom() : null}
      <SettingModal isOpen={open} onDismiss={toggle} content={modalBottom} />
    </StyledMenu>
  )
}

interface SettingModalProps {
  isOpen: boolean
  onDismiss: () => void
  content: () => ReactNode
}

function SettingModal({ isOpen, onDismiss, content }: SettingModalProps) {
  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      {content()}
    </Modal>
  )
}
