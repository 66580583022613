import { Trade, TradeType } from '@pancakeswap-libs/sdk'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Field } from '../../state/swap/actions'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { TYPE } from '../Shared'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import { AutoColumn } from '../Column'
import { t } from '@lingui/macro'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { SectionBreak } from './styleds'
import SwapRoute from './SwapRoute'

function TradeSummary({ trade, allowedSlippage }: { trade: Trade; allowedSlippage: number }) {
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)

  return (
    <>
      <AutoColumn>
        <RowBetween style={{ marginBottom: '8px' }}>
          <RowFixed>
            <TYPE.body fontSize={14} fontWeight={400}>
              {isExactIn ? 'Minimum received' : 'Maximum sold'}
            </TYPE.body>
            <QuestionHelper text={t`Your transaction will revert if there is a large, unfavorable price movement before it is confirmed`} />
          </RowFixed>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400}>
              {isExactIn ? (
                <>
                  {slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)}{' '}
                  <TYPE.body as="span" fontSize={14} fontWeight={400}>
                    {trade.outputAmount.currency.symbol}
                  </TYPE.body>
                </>
              ) : (
                <>
                  {slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)}{' '}
                  <TYPE.body as="span" fontSize={14} fontWeight={400}>
                    {trade.inputAmount.currency.symbol}
                  </TYPE.body>
                </>
              )}
            </TYPE.black>
          </RowFixed>
        </RowBetween>
        <RowBetween style={{ marginBottom: '8px' }}>
          <RowFixed>
            <TYPE.body fontSize={14} fontWeight={400}>
              Price Impact
            </TYPE.body>
            <QuestionHelper text={t`The difference between the market price and estimated price due to trade size`} />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TYPE.body fontSize={14} fontWeight={400}>
              Trade Fee
            </TYPE.body>
            <QuestionHelper text={t`The cost to complete the transaction`} />
          </RowFixed>
          <TYPE.black fontSize={14} fontWeight={400}>
            {realizedLPFee ? (
              <>
                {realizedLPFee.toSignificant(4)}{' '}
                <TYPE.body as="span" fontSize={14} fontWeight={400}>
                  {trade.inputAmount.currency.symbol}
                </TYPE.body>
              </>
            ) : (
              '-'
            )}
          </TYPE.black>
        </RowBetween>
      </AutoColumn>
    </>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade
}

export function AdvancedSwapDetails({ trade }: AdvancedSwapDetailsProps) {
  const theme = useContext(ThemeContext)

  const [allowedSlippage] = useUserSlippageTolerance()

  const AutoLine = styled.div`
    display: block;
    height: 1px;
    marging: 0 16px;
    background: ${({ theme }) => theme.colors.separator}
  `

  const showRoute = Boolean(trade && trade.route.path.length > 2)

  return (
    <AutoColumn gap="md">
      {trade && (
        <>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <>
              <SectionBreak />
              <AutoLine />
              <AutoColumn>
                <RowFixed>
                  <TYPE.black fontSize={14} fontWeight={400} color={theme.colors.textSecondary}>
                    Route
                  </TYPE.black>
                  <QuestionHelper text={t`Routing through these tokens resulted in the best price for your trade`} />
                </RowFixed>
                <SwapRoute trade={trade} />
              </AutoColumn>
            </>
          )}
        </>
      )}
    </AutoColumn>
  )
}
