import { transparentize } from 'polished'
import React from 'react'
import { AlertTriangle } from 'react-feather'
import styled, { css } from 'styled-components'
import { Text } from 'rebass'
import { AutoColumn, ColumnCenter } from '../Column'
import { IconFont } from '../IconFont'

export const DisPlayWrapper = styled.div<{ show: boolean }>`
  ${({ show }) =>
    !show
      ? css`
          display: none;
        `
      : null}
`
export const Wrapper = styled.div<{ show: boolean }>`
  position: relative;
  ${({ show }) =>
    !show
      ? css`
          display: none;
        `
      : null}
`

export const SwapWrapper = styled(AutoColumn)`
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  border-radius: 16px;
`

export const ArrowWrapper = styled.div<{ clickable: boolean }>`
  padding: 2px;

  ${({ clickable }) =>
    clickable
      ? css`
          :hover {
            cursor: pointer;
            opacity: 0.8;
          }
        `
      : null}
`
export const RepartWrapper = styled(ColumnCenter)`
  position: relative;
`

export const SectionBreak = styled.div`
  height: 1px;
  width: 100%;
  margin: 2px 0;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
`

export const BottomGrouping = styled.div`
  margin-top: 32px;
`

export const ErrorText = styled(Text) <{ severity?: 0 | 1 | 2 | 3 | 4 }>`
  color: ${({ theme, severity }) =>
    severity === 3 || severity === 4
      ? theme.colors.error
      : severity === 2
        ? theme.colors.yellow2
        : severity === 1
          ? theme.colors.primary1
          : theme.colors.success};
`

export const StyledBalanceMaxMini = styled.button`
  height: 22px;
  width: 22px;
  background-color: ${({ theme }) => theme.colors.bg};
  border: none;
  border-radius: 50%;
  padding: 0.2rem;
  font-size: 0.875rem;
  font-weight: 400;
  margin-left: 0.4rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary1};
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;

  :hover {
    background-color: ${({ theme }) => theme.colors.bg};
  }
  :focus {
    background-color: ${({ theme }) => theme.colors.bg};
    outline: none;
  }
`

export const TruncatedText = styled(Text)`
  text-overflow: ellipsis;
  width: 220px;
  overflow: hidden;
`

// styles
export const IconRepart = styled(IconFont)`
  position: absolute;
  top: -1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.textTertiary}
  border: 1px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  box-sizing: border-box;
  border-radius: 20px;
  width: 2.5rem;
  height: 2.5rem;
  transition: color .15s ease-in;
  z-index: 2;
  > * {
    width: 24px;
    height: 24px;
  }

  :hover {
    cursor: pointer;
    background: #fff;
  }
  :hover > *{
    color: ${({ theme }) => theme.colors.btnText};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    :hover {
      background-color: ${({ theme }) => theme.colors.bgTertiary};
    }
    :hover > *{
      color: ${({ theme }) => theme.colors.textTertiary}
    }
  `};

`

export const IconPlus = styled(IconFont)`
  position: absolute;
  top: -1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.textTertiary}
  border: 1px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  box-sizing: border-box;
  border-radius: 20px;
  width: 2.5rem;
  height: 2.5rem;
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textTheme}
  }
  transition: color .15s ease-in;
  z-index: 2;
`

const SwapCallbackErrorInner = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.colors.textFall)};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.825rem;
  width: 100%;
  padding: 3rem 1.25rem 1rem 1rem;
  margin-top: -2rem;
  color: ${({ theme }) => theme.colors.textFall};
  z-index: -1;
  p {
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
`

const SwapCallbackErrorInnerAlertTriangle = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.colors.textFall)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 12px;
  min-width: 48px;
  height: 48px;
`

export function SwapCallbackError({ error }: { error: string }) {
  return (
    <SwapCallbackErrorInner>
      <SwapCallbackErrorInnerAlertTriangle>
        <AlertTriangle size={24} />
      </SwapCallbackErrorInnerAlertTriangle>
      <p>{error}</p>
    </SwapCallbackErrorInner>
  )
}

export const SwapShowAcceptChanges = styled(AutoColumn)`
  background-color: ${({ theme }) => transparentize(0.9, theme.colors.primary1)};
  color: ${({ theme }) => theme.colors.primary1};
  padding: 0.5rem;
  border-radius: 12px;
  margin-top: 8px;
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`
