import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { NavLink, Link as HistoryLink } from 'react-router-dom'
import { t, Trans } from '@lingui/macro'
import Row, { RowBetween } from '../Row'
import QuestionHelper from '../QuestionHelper'
import { IconFont } from '../IconFont'
import { useToggleSettingsMenuPage } from '../../state/application/hooks'

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  justify-content: space-evenly;
  flex-wrap: nowrap;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 2rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 18px;
  flex: auto;
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-color: transparent;
  border: none;
  margin-right: 24px;

  &.${activeClassName} {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.btnText};
    border-bottom: 2px solid ${({ theme }) => theme.colors.btnText};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.colors.btnText)};
  }
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 16px;
  flex: auto;
  text-align: center;
`

const StyledArrowLeft = styled(IconFont)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.textSecondary};
  > * {
    width: 24px;
    height: 24px;
  }
`

const StyledPoolTabs = styled(Tabs)`
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  padding: 4px;
  border-radius: 1rem;
`

const StyledPoolNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 2.5rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 16px;

  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
  border: none;
  border-radius: 12px;

  &.${activeClassName} {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textPrimary};
    background-color: ${({ theme }) => theme.colors.bgSecondary};
  }

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.colors.bgTertiary};
    background-color: ${({ theme }) => theme.colors.bgTertiary};
  }
`

export function SwapPoolTabs({ active }: { active: 'swap' | 'pool' }) {
  return (
    <Tabs>
      <StyledNavLink id={`swap-nav-link`} to={'/swap'} isActive={() => active === 'swap'}>
        <Trans>Swap</Trans>
      </StyledNavLink>
      <StyledNavLink id={`pool-nav-link`} to={'/liquidity'} isActive={() => active === 'pool'}>
        <Trans id="PoolTab">Pool</Trans>
      </StyledNavLink>
    </Tabs>
  )
}

export function FindPoolTabs() {
  return (
    <Tabs>
      <Row>
        <HistoryLink to="/liquidity">
          <StyledArrowLeft type="icon-chevron_left" />
        </HistoryLink>
        <ActiveText>Import Pool</ActiveText>
      </Row>
    </Tabs>
  )
}

export function SettingTabs() {
  const pcToggle = useToggleSettingsMenuPage()
  return (
    <Tabs>
      <Row>
        <HistoryLink to="/swap" onClick={pcToggle}>
          <StyledArrowLeft type="icon-chevron_left" />
        </HistoryLink>
        <ActiveText>Transaction Settings</ActiveText>
      </Row>
    </Tabs>
  )
}

export function PoolTabs({ active }: { active: 'add' | 'your' }) {
  return (
    <StyledPoolTabs>
      <StyledPoolNavLink id={`your-liquidity-nav-link`} to={'/liquidity'} isActive={() => active === 'your'}>
        <Trans>Your Liquidity</Trans>
      </StyledPoolNavLink>
      <StyledPoolNavLink id={`add-pool-nav-link`} to={'/add/OKB'} isActive={() => active === 'add'}>
        <Trans>Add</Trans>
      </StyledPoolNavLink>
    </StyledPoolTabs>
  )
}

export function AddRemoveTabs({ adding }: { adding: boolean }) {
  return (
    <Tabs>
      <RowBetween>
        <HistoryLink to="/liquidity">
          <StyledArrowLeft type="icon-chevron_left" />
        </HistoryLink>
        <ActiveText>{adding ? 'Add' : 'Remove'} Liquidity</ActiveText>
        <QuestionHelper
          text={
            adding
              ? t`When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time`
              : t`Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive`
          }
        />
      </RowBetween>
    </Tabs>
  )
}
