import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import MobileFooter from './MobileFooter'
import { Trans } from '@lingui/macro'
// import logoFooter from '../../assets/images/logo.png'
// import { DEFAULT_LOCALE, SupportedLocale } from '../../constants/locales'
// import { useActiveLocale } from '../../hooks/useActiveLocale'

const WebFooter = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  position: relative;
  bottom: 0px;
  width: 100%;
  flex: 0 0 auto;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.bgFooter};
`
const FooterFrame = styled.div`
  display: flex;
  z-index: 2;
  width: 1200px;
  margin: 0 auto;
  padding: 80px 0;
  box-sizing: bordr-box;
  justify-content: center;
  background: ${({ theme }) => theme.colors.bgFooter};
`

// const FootLogo = styled.div`
//   display: block;
//   height: 32px;
// `

const LinkTabll = styled.div`
  display: flex;
  justify-content: felx-end;
  flex-direction: column;
`
const TabllCell = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`
const CellTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-right: 32px;
  color: ${({ theme }) => theme.colors.textSecondary};
`
const CellItem = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-right: 12px;
  color: ${({ theme }) => theme.colors.textTertiary};
  &:hover {
    color: ${({ theme }) => theme.colors.textTheme};
  }
`
const StyledAbsoluteLink = styled.a`
  position: relative;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textTertiary};
  &:not(.not-active):hover {
    color: ${({ theme }) => theme.colors.textTheme};
  }
  &.active {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
  &.not-active {
    opacity: 0.4;
    cursor: not-allowed;
  }
`

export default function Footer() {
  // const activeLocale = useActiveLocale()
  // let targetLocale: SupportedLocale
  // if (activeLocale) {
  //   targetLocale = activeLocale
  // } else {
  //   targetLocale = DEFAULT_LOCALE
  // }
  return (
    <>
      {!isMobile ? (
        <WebFooter>
          <FooterFrame id="FooterFrame">
           {/* src={logoFooter} alt="footerlogo" */}
            {/* <FootLogo /> */}
            <LinkTabll>
              {/* <TabllCell>
                <CellTitle>
                  <Trans>商务合作</Trans>
                </CellTitle>
                <CellItem>
                  <StyledAbsoluteLink href={`https://zestswap.org/${targetLocale}/coin-apply`}>
                    <Trans>上币申请</Trans>
                  </StyledAbsoluteLink>
                </CellItem>
                <CellItem>
                  <StyledAbsoluteLink href={`https://zestswap.org/${targetLocale}/mining-power-apply`}>
                    <Trans>挖矿激励申请</Trans>
                  </StyledAbsoluteLink>
                </CellItem>
                <CellItem>
                  <StyledAbsoluteLink href={`https://zestswap.org/${targetLocale}/ido-apply`}>
                    <Trans>IDO 申请</Trans>
                  </StyledAbsoluteLink>
                </CellItem>
              </TabllCell> */}

              <TabllCell>
                <CellTitle>
                  <Trans>About</Trans>
                </CellTitle>
                <CellItem>
                  <StyledAbsoluteLink
                    className="not-active"
                  >
                    <Trans>Doc</Trans>
                  </StyledAbsoluteLink>
                </CellItem>
                {/* <CellItem>
                  <StyledAbsoluteLink>
                    <Trans>Donate</Trans>
                  </StyledAbsoluteLink>
                </CellItem> */}
              </TabllCell>

              <TabllCell>
                <CellTitle>
                  <Trans>Media</Trans>
                </CellTitle>
                <CellItem>
                  <StyledAbsoluteLink href="https://discord.gg/CHcUuRMrU8" target='_blank'>
                    <Trans>Discord</Trans>
                  </StyledAbsoluteLink>
                </CellItem>
                <CellItem>
                  <StyledAbsoluteLink href="https://twitter.com/ZestSwap" target='_blank'>
                    <Trans>Twitter</Trans>
                  </StyledAbsoluteLink>
                </CellItem>
                {/* <CellItem>
                  <StyledAbsoluteLink
                    className='not-active'
                  >
                    <Trans>Discord</Trans>
                  </StyledAbsoluteLink>
                </CellItem> */}
                <CellItem>
                  <StyledAbsoluteLink
                    className='not-active'
                  >
                    <Trans>Telegram</Trans>
                  </StyledAbsoluteLink>
                </CellItem>
                <CellItem>
                  <StyledAbsoluteLink className='not-active'>
                    <Trans>Github</Trans>
                  </StyledAbsoluteLink>
                </CellItem>
              </TabllCell>
            </LinkTabll>
          </FooterFrame>
        </WebFooter>
      ) : (
        <MobileFooter />
      )}
    </>
  )
}
