import React, { createContext, useEffect, useState, useCallback } from 'react'
import BigNumber from 'bignumber.js'
import multicall from '../utils/multicall'
import erc20 from '../constants/abis/erc20.json'
import { getBalanceNumber } from '../utils/formatBalance'
import { useBlock } from '../hooks/useBlock'

export interface PriceContext {
  htPrice: number
  pippiPrice: number
}

export const Context = createContext<PriceContext>({
  htPrice: 0,
  pippiPrice: 0
})

const PriceProvider: React.FC = ({ children }) => {
  const block = useBlock()
  const [price, setPrice] = useState({
    htPrice: 0,
    pippiPrice: 0
  })

  const fetchBalance = useCallback(async () => {
    const calls = [
      {
        address: '0x1e4a5963abfd975d8c9021ce480b42188849d41d', //USDT
        name: 'balanceOf',
        params: ['0xd346967e8874b9c4dcdd543a88ae47ee8c8bd21f'] //okt-ks
      },
      {
        address: '0xe538905cf8410324e03a5a23c1c177a474d59b2b', //OKB
        name: 'balanceOf',
        params: ['0xd346967e8874b9c4dcdd543a88ae47ee8c8bd21f'] //LP Token okt-ks
      },
      {
        address: '0x0000000000000000000000000000000000000000', //ZEST
        name: 'balanceOf',
        params: ['0xcf5c645da27e0164eda3e2a1002d078ebe80b5c5']
      },
      {
        address: '0x1e4a5963abfd975d8c9021ce480b42188849d41d', //USDT
        name: 'balanceOf',
        params: ['0xcf5c645da27e0164eda3e2a1002d078ebe80b5c5'] //LP Token
      }
    ]
    try {
      const [busd, bnb0, cake, bnb1] = await multicall(erc20, calls)
      const htPrice = getBalanceNumber(new BigNumber(busd)) / getBalanceNumber(new BigNumber(bnb0))
      const cakebnb = getBalanceNumber(new BigNumber(bnb1)) / getBalanceNumber(new BigNumber(cake))
      const pippiPrice = cakebnb * htPrice
      setPrice({
        htPrice,
        pippiPrice
      })
    } catch (error) {
      console.log('error..', error)
    }
  }, [])

  useEffect(() => {
    // fetchBalance()
  }, [setPrice, block, fetchBalance])

  return <Context.Provider value={{ ...price }}>{children}</Context.Provider>
}

export default PriceProvider
