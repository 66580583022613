import React from 'react'
import Logo from '../Header/Logo'
import styled, { keyframes } from 'styled-components'
import { Trans } from '@lingui/macro'
import { ReactComponent as Close } from '../../assets/images/x.svg'
// import { DEFAULT_LOCALE, LOCALE_LABEL, SupportedLocale } from '../../constants/locales'
// import { useActiveLocale } from '../../hooks/useActiveLocale'
// import { Link } from 'react-router-dom'
// import { useLocation } from 'react-router'
// import useParsedQueryString from '../../hooks/useParsedQueryString'
// import { stringify } from 'qs'
// import WechatHelper from '../Footer/WeChatHelp'

interface MobileMenuProps {
  onDismiss: () => void
  visible?: boolean
}

// eslint-disable-next-line react/prop-types
const MobileMenu: React.FC<MobileMenuProps> = ({ onDismiss, visible }) => {
  // const activeLocale = useActiveLocale()
  // const location = useLocation()
  // const qs = useParsedQueryString()

  // let targetLocale: SupportedLocale
  // if (activeLocale) {
  //   targetLocale = activeLocale
  // } else {
  //   targetLocale = DEFAULT_LOCALE
  // }

  if (visible) {
    return (
      <StyledMobileModalWrapper>
        <StyledBackdrop onClick={onDismiss} />
        <StyledMobileModal>
          <div style={{ margin: '14px 38px 0 16px' }}>
            <Logo />
          </div>
          <CloseIcon onClick={onDismiss}></CloseIcon>

          <ModalContent>
            <HeaderTitle>About</HeaderTitle>

            <HeaderBtnBox>
              <HeaderExternalBtn
                className='not-active'
              >
                <Trans>Doc</Trans>
              </HeaderExternalBtn>
              {/* <HeaderBtn to="/">Donate</HeaderBtn> */}
            </HeaderBtnBox>

            <HeaderTitle>Media</HeaderTitle>

            <HeaderBtnBox>
              {/* <HeaderExternalBtn href="#">
                <WechatHelper text={t`Wechart`} />
              </HeaderExternalBtn> */}
              <HeaderExternalBtn
                href={'https://discord.gg/CHcUuRMrU8'}
              >
                Discord
              </HeaderExternalBtn>
              <HeaderExternalBtn href="https://twitter.com/ZestSwap">Twitter</HeaderExternalBtn>
              {/* <HeaderExternalBtn
                className='not-active'
              >
                Discord
              </HeaderExternalBtn> */}
              <HeaderExternalBtn
                className='not-active'
              >
                Telegram
              </HeaderExternalBtn>
              <HeaderExternalBtn className='not-active'>Github</HeaderExternalBtn>
              {/* <HeaderBtn to="#" className="not-active">
                Github
              </HeaderBtn> */}
            </HeaderBtnBox>

            {/* <HeaderTitle>Language</HeaderTitle>

            <HeaderBtnBox>
              {SUPPORTED_LOCALES.map((key: SupportedLocale) => {
                const target = {
                  ...location,
                  search: stringify({ ...qs, lng: key })
                }
                return (
                  <HeaderBtn
                    key={key}
                    onClick={onDismiss}
                    to={target}
                    className={targetLocale === key ? 'active' : 'unactice'}
                  >
                    {LOCALE_LABEL[key]}
                  </HeaderBtn>
                )
              })}
            </HeaderBtnBox> */}
          </ModalContent>

          {/* <LngSwith className="mobile-lng-swith"></LngSwith> */}
        </StyledMobileModal>
      </StyledMobileModalWrapper>
    )
  }
  return null
}

const CloseIcon = styled(Close)`
  position: absolute;
  top: 17.6px;
  right: 20.6px;
  width: 18px;
  height: 18px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  > * {
    color: ${({ theme }) => theme.colors.textSecondary};
  }
`

const StyledBackdrop = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(80, 89, 88, 0.7);
`

const StyledMobileModalWrapper = styled.div`
  position: fixed;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: rgba(9, 11, 16, 0.91);
  backdrop-filter: blur(2.5px);
`

const slideIn = keyframes`
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
`

const StyledMobileModal = styled.div`
  position: absolute;
  top: -100%;
  z-index: 11;
  width: 100%;
  background: ${({theme}) => theme.colors.bgSecondary};
  border-radius: 0 0 16px 16px;
  animation: ${slideIn} 0.3s forwards ease-out;
  // box-shadow: 0px 8px 16px rgba(127, 137, 163, 0.16);
  // .mobile-lng-swith {
  //   width: 100 %;
  //   height: 30px;
  //   line-height: 30px;
  //   margin-bottom: 20px;
  // }
`

const HeaderTitle = styled.div`
  color: ${({theme}) => theme.colors.textSecondary};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
  margin-top: 16px;
`

const HeaderBtnBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`
// const HeaderBtn = styled(Link)`
//   font-size: 12px;
//   line-height: 16px;
//   padding: 8px 12px;
//   margin: 0 8px 8px 0;
//   border-radius: 12px;
//   color: ${({ theme }) => theme.colors.btnText};
//   background: ${({ theme }) => theme.colors.bgTertiary};
//   text-decoration: none;

//   &.active {
//     background: ${({ theme }) => theme.colors.bg};
//     border: 1px solid ${({ theme }) => theme.colors.btnText};
//   }

//   &.not-active {
//     opacity: 0.4;
//     cursor: not-allowed;
//   }
// `
const HeaderExternalBtn = styled.a`
  font-size: 12px;
  line-height: 16px;
  padding: 8px 12px;
  margin: 0 8px 8px 0;
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.btnText};
  background: ${({ theme }) => theme.colors.bgTertiary};
  text-decoration: none;

  &.active {
    background: ${({ theme }) => theme.colors.bg};
    border: 1px solid ${({ theme }) => theme.colors.btnText};
  }

  &.not-active {
    opacity: 0.4;
    cursor: not-allowed;
  }
`

const ModalContent = styled.div`
  padding: 0 24px 40px;
`

export default MobileMenu
