import React from 'react'
import styled from 'styled-components'
import { ExternalLink } from '../Shared'

const InfoCard = styled.button<{ active?: boolean }>`
  border: none;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  padding: 4px 24px 4px 4px;
  outline: none;
  height: 64px;
  border-radius: 32px;
  box-sizing: border-box;
  width: 100% !important;
  cursor: pointer;
`

const OptionCard = styled(InfoCard as any)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const OptionCardRight = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  justify-content: center;
  height: 100%;
  flex: 1;
`

const OptionCardClickable = styled(OptionCard as any)<{ clickable?: boolean }>`
  margin-bottom: 8px;
  &:hover {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : '')};
    border: ${({ clickable, theme }) => (clickable ? `1px solid ${theme.colors.primary1}` : ``)};
  }
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  &:last-child() {
    margin-bottom: 0px;
  }
`

const GreenCircle = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  justify-content: center;
  align-items: center;

  &:first-child {
    height: 8px;
    width: 8px;
    margin-right: 8px;
    background-color: ${({ theme }) => theme.colors.success};
    border-radius: 50%;
  }
`

const CircleWrapper = styled.div`
  color: ${({ theme }) => theme.colors.success};
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderText = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  color: ${props =>
    props.color === 'blue' ? ({ theme }) => theme.colors.primary1 : ({ theme }) => theme.colors.primary1};
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  justify-content: center;
`

const SubHeader = styled.div`
  color: ${({ theme }) => theme.colors.primary1};
  margin-top: 10px;
  font-size: 12px;
`

const IconWrapper = styled.div<{ size?: number | null }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  width: 56px;
  height: 56px;
  background: #fff;
  margin-right: 8px;
  border-radius: 28px;
  align-items: center;
  justify-content: center;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '24px')};
    width: ${({ size }) => (size ? size + 'px' : '24px')};
  }
  // ${({ theme }) => theme.mediaWidth.upToMedium`
  //   align-items: flex-end;
  // `};
`

export default function Option({
  link = null,
  clickable = true,
  size,
  onClick = null,
  color,
  header,
  subheader = null,
  icon,
  active = false,
  id
}: {
  link?: string | null
  clickable?: boolean
  size?: number | null
  onClick?: null | (() => void)
  color: string
  header: React.ReactNode
  subheader: React.ReactNode | null
  icon: string
  active?: boolean
  id: string
}) {
  const content = (
    <OptionCardClickable id={id} onClick={onClick} clickable={clickable && !active} active={active}>
      <IconWrapper size={size}>
        <img src={icon} alt={'Icon'} />
      </IconWrapper>
      <OptionCardRight>
        <HeaderText color={color}>
          {active ? (
            <CircleWrapper>
              <GreenCircle>
                <div />
              </GreenCircle>
            </CircleWrapper>
          ) : (
            ''
          )}
          {header}
        </HeaderText>
        {subheader && <SubHeader>{subheader}</SubHeader>}
      </OptionCardRight>
    </OptionCardClickable>
  )
  if (link) {
    return <ExternalLink href={link}>{content}</ExternalLink>
  }

  return content
}
