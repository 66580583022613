import React from 'react'
import styled from 'styled-components/macro'
import { SettingButton } from '../Settings'
import { SwapPoolTabs } from '../../components/NavigationTabs'

import { RowBetween, RowFixed } from '../Row'

const StyledSwapHeader = styled.div`
  padding-bottom: 20px;
  width: 100%;
`

export default function PageHeader({ active }: { active: 'swap' | 'pool' }) {
  return (
    <StyledSwapHeader>
      <RowBetween>
        <RowFixed>
          <SwapPoolTabs active={active} />
        </RowFixed>
        <RowFixed>{active === 'swap' && <SettingButton />}</RowFixed>
      </RowBetween>
    </StyledSwapHeader>
  )
}
