import React from 'react'
import { Trans } from '@lingui/macro'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { judgeBigScreen } from '../../utils/tools'
// import { DEFAULT_LOCALE, SupportedLocale } from '../../constants/locales'
import { useActiveLocale } from '../../hooks/useActiveLocale'

interface FooterModalProps {
  onDismiss: () => void
  visible?: boolean
}

// eslint-disable-next-line react/prop-types
const FooterModal: React.FC<FooterModalProps> = ({ onDismiss, visible }) => {
  const activeLocale = useActiveLocale()
  // let targetLocale: SupportedLocale
  if (activeLocale) {
    // targetLocale = activeLocale
  } else {
    // targetLocale = DEFAULT_LOCALE
  }
  if (visible) {
    return (
      <StyledMobileModalWrapper>
        <StyledBackdrop onClick={onDismiss} />
        <StyledMobileModal bigScreen={judgeBigScreen()}>
          {/* <ModalBtn href={`https://zestswap.org/${targetLocale}/liquidity`}>
            <BtnIcon style={{ backgroundPosition: '-96px -24px' }}></BtnIcon>
            <Trans>Pool</Trans>
          </ModalBtn> */}

          {/* <ModalBtn href="https://zestswap.org/burn">
            <BtnIcon style={{ backgroundPosition: '22px -24px' }}></BtnIcon>
            <Trans>Burn</Trans>
          </ModalBtn> */}
          {/* href="https://info-oec.cocoswap.com" */}
          <ModalBtn style={{ opacity: '0.4' }}>
            <BtnIcon style={{ backgroundPosition: '-96px -24px' }}></BtnIcon>
            <Trans>Pool</Trans>
          </ModalBtn>
          <ModalBtn style={{ opacity: '0.4' }}>
            <BtnIcon style={{ backgroundPosition: '-120px -24px' }}></BtnIcon>
            <Trans>Info</Trans>
          </ModalBtn>

          <ModalBtn style={{ opacity: '0.4' }}>
            <BtnIcon style={{ backgroundPosition: '-168px -24px' }}></BtnIcon>
            <Trans>bridge</Trans>
          </ModalBtn>

          <CloseIcon onClick={onDismiss}></CloseIcon>
        </StyledMobileModal>
      </StyledMobileModalWrapper>
    )
  }
  return null
}

const CloseIcon = styled(Close)`
  display: block;
  width: 24px;
  height: 24px;
  margin: 30px auto 0;
  > * {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.bg};
  }

`

const StyledBackdrop = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(80, 89, 88, 0.7);
`

const StyledMobileModalWrapper = styled.div`
  position: fixed;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
`

const slideIn = keyframes`
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 30px;
  }
`

const StyledMobileModal = styled(({ bigScreen, ...rest }) => (
  <div {...rest} />
))`
  position: absolute;
  bottom: -100%;
  z-index: 11;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  animation: ${slideIn} 0.3s forwards ease-out;
  padding-bottom: ${({ bigScreen }) => (bigScreen ? `24px` : '0')};
`

const ModalBtn = styled.a`
  display: flex;
  width: 215px;
  height: 64px;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 auto 12px;
  align-items: centet;
  border-radius: 24px;
  text-decoration: none;
  background: ${({ theme }) => theme.colors.bg};
  color: ${({ theme }) => theme.colors.textPrimary};
`
const BtnIcon = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 24px 0 30px;
  background-size: 192px auto;
  background-image: url('/footerIcon.png');
`

export default FooterModal
