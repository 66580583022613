import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { AppDispatch } from '../../state'
import { clearAllTransactions } from '../../state/transactions/actions'
import { shortenAddress } from '../../utils'
import { AutoRow } from '../Row'
import { AutoColumn } from '../Column'
import Copy from './Copy'
import Transaction from './Transaction'
import { Trans } from '@lingui/macro'
import { SUPPORTED_WALLETS } from '../../constants'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { getEtherscanLink } from '../../utils'
import { injected, walletlink } from '../../connectors'
// import CoinbaseWalletIcon from '../../assets/images/coinbaseWalletIcon.svg'
// import WalletConnectIcon from '../../assets/images/walletConnectIcon.svg'
// import FortmaticIcon from '../../assets/images/fortmaticIcon.png'
// import PortisIcon from '../../assets/images/portisIcon.png'
// import Identicon from '../Identicon'
import { ButtonSecondary } from '../Button'
import { ExternalLink, LinkStyledButton, TYPE } from '../Shared'
import { IconFont } from '../IconFont'
// import { useTokenBalance } from '../../state/wallet/hooks'
import { useOKBBalances } from '../../state/wallet/hooks'
// import { ZEST } from '../../constants'

export const IconRepart = styled(IconFont)`
  color: ${({ theme }) => theme.colors.textTertiary}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  > * {
    width: 20px;
    height: 20px;
  }
`

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  font-weight: 500;
  color: ${props => (props.color === 'blue' ? ({ theme }) => theme.colors.primary1 : 'inherit')};
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const InfoCard = styled.div`
  display: flex;
  padding: 12px 24px;
  border-radius: 16px;
  position: relative;
  margin: 16px 0;
  justify-content: space-between;
  background: linear-gradient(90.02deg, #4339EE 0.02%, #4583DF 99.98%);
`

const CardItem = styled.div`
  flex: 1;
`
const CardTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textSecondary};
`
const CardLabel = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimary};
`

const AccountGroupingRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textPrimary};

  div {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
  }
`

const AccountSection = styled.div`
  padding: 0;
`

const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  padding: 1.5rem 0 0 0.5rem;
  flex-grow: 1;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.bg};
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 20px;

  h5 {
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.primary1};
  }
`

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.textSecondary};

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const AddressLink = styled(ExternalLink) <{ hasENS: boolean; isENS: boolean }>`
  color: ${({ theme }) => theme.colors.textSecondary};
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  margin-left: .5rem;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  padding: 10px 20px;
  flex: 1;
  height: 36px;
  border-radius: 12px;
  justify-content: center;
  align-items:center;
  > * {
    text-decoration: none;
  }
  :hover,
  :active,
  :focus {
    text-decoration: none!important;
    color: ${({ theme }) => theme.colors.textTheme};
    svg {
      color: ${({ theme }) => theme.colors.textTheme};
    }
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: block;
    width: auto;
    height: 56px;
    padding: 8px 0;
    font-size: 12px;
    line-height: 16px;
    box-sizing: border-box;
    :hover,
    :active,
    :focus {
      color: ${({ theme }) => theme.colors.textSecondary};
      background-color: ${({ theme }) => theme.colors.bgTertiary};
    }
    > span{
      display: block;
      margin: 0 auto;
    }
    > div{
      display: block;
      text-align: center;
      margin: 4px 0 0 0 !important;
    }
  `};
`

const CloseIcon = styled(Close)`
  position: absolute;
  top: 4px;
  right: 4px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  > * {
    color: ${({ theme }) => theme.colors.textSecondary};
  }
`

const WalletName = styled.div`
  width: initial;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textSecondary};
`

// const IconWrapper = styled.div<{ size?: number }>`
//   ${({ theme }) => theme.flexColumnNoWrap};
//   align-items: center;
//   justify-content: center;
//   margin-right: 8px;
//   & > img,
//   span {
//     height: ${({ size }) => (size ? size + 'px' : '32px')};
//     width: ${({ size }) => (size ? size + 'px' : '32px')};
//   }
//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     align-items: flex-end;
//   `};
// `

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
`

const WalletAction = styled(ButtonSecondary)`
  flex: 1;
  height: 36px;
  color: ${({ theme }) => theme.colors.textSecondary};
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  padding: 10px 20px;
  border-radius: 12px;
  font-weight: 400;
  margin-left: 8px;
  font-size: 14px;
  line-height: 20px;
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textTheme};
    svg {
      color: ${({ theme }) => theme.colors.textTheme};
    }
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: block;
    height: 56px;
    padding: 8px 0;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 16px;
    :hover,
    :active,
    :focus {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.textSecondary};
      background-color: ${({ theme }) => theme.colors.bgTertiary};
    }
    > span{
      display: block;
      margin: 0 auto;
    }
    > div{
      display: block;
      text-align: center;
      margin: 4px 0 0 0 !important;
    }
  `};

`


// const MainWalletAction = styled(WalletAction)`
//   color: ${({ theme }) => theme.colors.primary1};
// `

function renderTransactions(transactions: string[]) {
  return (
    <TransactionListWrapper>
      {transactions.map((hash, i) => {
        return <Transaction key={i} hash={hash} />
      })}
    </TransactionListWrapper>
  )
}

interface AccountDetailsProps {
  toggleWalletModal: () => void
  pendingTransactions: string[]
  confirmedTransactions: string[]
  ENSName?: string
  openOptions: () => void
}

export default function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions,
  ENSName,
  openOptions
}: AccountDetailsProps) {
  const { chainId, account, connector, deactivate } = useActiveWeb3React()
  const userEthBalance = useOKBBalances(account ? [account] : [])?.[account ?? '']
  // const userCoCoBalance = useTokenBalance(account ?? undefined, ZEST)
  const dispatch = useDispatch<AppDispatch>()

  function formatConnectorName() {
    const { ethereum } = window
    const isMetaMask = !!(ethereum && ethereum.isMetaMask)
    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        k =>
          SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isMetaMask === (k === 'METAMASK'))
      )
      .map(k => SUPPORTED_WALLETS[k].name)[0]
    return <WalletName>{name}</WalletName>
  }

  // function getStatusIcon() {
  //   if (connector === injected) {
  //     return (
  //       <IconWrapper size={16}>
  //         <Identicon />
  //       </IconWrapper>
  //     )
  //   } else if (connector === walletconnect) {
  //     return (
  //       <IconWrapper size={16}>
  //         <img src={WalletConnectIcon} alt={'wallet connect logo'} />
  //       </IconWrapper>
  //     )
  //   } else if (connector === walletlink) {
  //     return (
  //       <IconWrapper size={16}>
  //         <img src={CoinbaseWalletIcon} alt={'coinbase wallet logo'} />
  //       </IconWrapper>
  //     )
  //   } else if (connector === fortmatic) {
  //     return (
  //       <IconWrapper size={16}>
  //         <img src={FortmaticIcon} alt={'fortmatic logo'} />
  //       </IconWrapper>
  //     )
  //   } else if (connector === portis) {
  //     return (
  //       <>
  //         <IconWrapper size={16}>
  //           <img src={PortisIcon} alt={'portis logo'} />
  //           <MainWalletAction
  //             onClick={() => {
  //               portis.portis.showPortis()
  //             }}
  //           >
  //             Show Portis
  //           </MainWalletAction>
  //         </IconWrapper>
  //       </>
  //     )
  //   }
  //   return null
  // }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }))
  }, [dispatch, chainId])

  return (
    <>
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}></CloseIcon>
        <HeaderRow>
          <AutoColumn>
            <AccountGroupingRow>{account && shortenAddress(account)}</AccountGroupingRow>
            <AccountGroupingRow>{formatConnectorName()}</AccountGroupingRow>
          </AutoColumn>
        </HeaderRow>
        <AccountSection>
          <YourAccount>
            <InfoCard>
              {/* <AccountGroupingRow id="web3-account-identifier-row">
                <AccountControl>
                  {ENSName ? (
                    <>
                      <div>
                        {getStatusIcon()}
                        <p> {ENSName}</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        {getStatusIcon()}
                        <p> {account && shortenAddress(account)}</p>
                      </div>
                    </>
                  )}
                </AccountControl>
              </AccountGroupingRow> */}


              <CardItem>
                <CardTitle>OKB</CardTitle>
                <CardLabel>{userEthBalance?.toSignificant(6)}</CardLabel>
              </CardItem>
              <CardItem style={{ textAlign: 'right' }}>
                {/* <CardTitle>ZEST</CardTitle> */}
                {/* <CardLabel>{userCoCoBalance?.toSignificant(6)}</CardLabel> */}
              </CardItem>

            </InfoCard>
            <AccountGroupingRow>
              {ENSName ? (
                <>
                  <AccountControl>
                    <div style={{ width: '100%' }}>
                      {account && (
                        <Copy toCopy={account}>
                          <div style={{ marginLeft: '8px', whiteSpace: 'nowrap' }}><Trans>Copy Address</Trans></div>
                        </Copy>
                      )}
                      {chainId && account && (
                        <AddressLink
                          hasENS={!!ENSName}
                          isENS={true}
                          href={chainId && getEtherscanLink(chainId, ENSName, 'address')}
                        >
                          <IconRepart type='icon-link' />
                          <div style={{ marginLeft: '8px' }}><Trans>View</Trans></div>
                        </AddressLink>
                      )}
                    </div>
                  </AccountControl>
                </>
              ) : (
                <>
                  <AccountControl>
                    <div style={{ width: '100%' }}>
                      {account && (
                        <Copy toCopy={account}>
                          <div style={{ marginLeft: '8px', whiteSpace: 'nowrap' }}><Trans>Copy Address</Trans></div>
                        </Copy>
                      )}
                      {chainId && account && (
                        <AddressLink
                          hasENS={!!ENSName}
                          isENS={false}
                          href={getEtherscanLink(chainId, account, 'address')}
                        >
                          <IconRepart type='icon-link' />
                          <div style={{ marginLeft: '8px' }}><Trans>View</Trans></div>
                        </AddressLink>
                      )}
                      {connector !== injected && connector !== walletlink ? (
                        <WalletAction
                          style={{ fontSize: '.825rem', fontWeight: 400, marginRight: '8px' }}
                          onClick={() => {
                            try {
                              deactivate()
                            } catch (err) {
                              console.log(err)
                              openOptions()
                            }
                          }}
                        >
                          <IconRepart type='icon-nolink' />
                          <div style={{ marginLeft: '8px' }}><Trans>Disconnect</Trans></div>
                        </WalletAction>
                      ) : (
                        <WalletAction
                          style={{ fontSize: '.825rem', fontWeight: 400 }}
                          onClick={() => {
                            openOptions()
                          }}
                        >
                          <IconRepart type='icon-nolink' />
                          <div style={{ marginLeft: '8px' }}><Trans>Disconnect</Trans></div>
                        </WalletAction>
                      )}
                    </div>
                  </AccountControl>
                </>
              )}
            </AccountGroupingRow>
          </YourAccount>
        </AccountSection>
      </UpperSection>
      {
        !!pendingTransactions.length || !!confirmedTransactions.length ? (
          <LowerSection>
            <AutoRow mb={'1rem'} style={{ justifyContent: 'space-between' }}>
              <TYPE.body><Trans>Recent Transactions</Trans></TYPE.body>
              <LinkStyledButton onClick={clearAllTransactionsCallback}>(<Trans>clear all</Trans>)</LinkStyledButton>
            </AutoRow>
            {renderTransactions(pendingTransactions)}
            {renderTransactions(confirmedTransactions)}
          </LowerSection>
        ) : null
      }
    </>
  )
}
