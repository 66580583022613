import { JSBI, Pair, Percent } from '@pancakeswap-libs/sdk'
import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { useTotalSupply } from '../../data/TotalSupply'
import { ONE_BIPS } from '../../constants'

import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonPrimary } from '../Button'
import { Trans } from '@lingui/macro'
import Card from '../Card'
import { AutoColumn } from '../Column'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowFixed } from '../Row'
import { Dots } from '../swap/styleds'
import { TYPE } from '../Shared/Type'
import { IconFont } from '../IconFont';

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`
export const FixedTabtRow = styled(RowBetween)`
  height: 32px;
`

export const HoverCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.bgSecondary};
  padding: 0;
`
export const LightCard = styled(Card)`
  padding: 24px 32px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 24px 16px;
`};
`

export const CardWrapper = styled(AutoColumn)`
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  border-radius: 16px;
  padding: 1rem 0.75rem;
`

const RightIcon = styled.div`
  > * {
    width: 24px;
    height: 24px;
    > svg {
      width: 24px;
      height: 24px;
    }
  }
`

interface PositionCardProps {
  pair: Pair
  showUnwrapped?: boolean
  border?: string
}

export function MinimalPositionCard({ pair, showUnwrapped = false, border }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)
  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
      !!totalPoolTokens &&
      !!userPoolBalance &&
      // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
      JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
        pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
        pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
      ]
      : [undefined, undefined]

  return (
    <>
      {userPoolBalance && userPoolBalance?.greaterThan(ONE_BIPS) && (
        <LightCard border={border}>
          <AutoColumn gap="12px">
            <AutoColumn gap="4px">
              <FixedHeightRow>
                <TYPE.main fontSize={14} fontWeight={500}>
                  <Trans>Your pool tokens</Trans>
                </TYPE.main>
                {userPoolBalance ? (
                  <RowFixed>
                    <TYPE.black fontSize={14} fontWeight={500} marginLeft={'6px'}>
                      {userPoolBalance.toSignificant(4)}
                    </TYPE.black>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <TYPE.main fontSize={14} fontWeight={500}>
                  <Trans>Pooled</Trans> {currency0.symbol}:
                </TYPE.main>
                {token0Deposited ? (
                  <RowFixed>
                    <TYPE.black fontSize={14} fontWeight={500} marginLeft={'6px'}>
                      {token0Deposited?.toSignificant(6)}
                    </TYPE.black>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <TYPE.main fontSize={14} fontWeight={500}>
                  <Trans>Pooled</Trans> {currency1.symbol}:
                </TYPE.main>
                {token1Deposited ? (
                  <RowFixed>
                    <TYPE.black fontSize={14} fontWeight={500} marginLeft={'6px'}>
                      {token1Deposited?.toSignificant(6)}
                    </TYPE.black>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <TYPE.main fontSize={14} fontWeight={500}>
                  <Trans>Your pool share</Trans>:
                </TYPE.main>
                {poolTokenPercentage ? (
                  <RowFixed>
                    <TYPE.black fontSize={14} fontWeight={500} marginLeft={'6px'}>
                      {poolTokenPercentage.greaterThan(new Percent(JSBI.BigInt(1), JSBI.BigInt(1000)))
                        ? poolTokenPercentage.toFixed(2) + '%'
                        : '<0.001%'}
                    </TYPE.black>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
            </AutoColumn>
          </AutoColumn>
        </LightCard>
      )}
    </>
  )
}

export default function FullPositionCard({ pair, border }: PositionCardProps) {
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
      !!totalPoolTokens &&
      !!userPoolBalance &&
      // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
      JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
        pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
        pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
      ]
      : [undefined, undefined]

  return (
    <HoverCard border={border}>
      <AutoColumn gap="12px">
        <FixedTabtRow onClick={() => setShowMore(!showMore)} style={{ cursor: 'pointer' }}>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={32} />
            <Text fontWeight={500} fontSize={16} style={{ marginLeft: '1rem' }}>
              {!currency0 || !currency1 ? <Dots><Trans>Loading</Trans></Dots> : `${currency0.symbol}/${currency1.symbol}`}
            </Text>
          </RowFixed>
          <RowFixed>
            {showMore ? (
              <RightIcon>
                <IconFont style={{ marginLeft: '10px', color: theme.colors.primary1 }} type="icon-arrow_up" />
              </RightIcon>
            ) : (
              <RightIcon>
                <IconFont style={{ marginLeft: '10px', color: theme.colors.textTertiary }} type="icon-arrow_down" />
              </RightIcon>
            )}
          </RowFixed>
        </FixedTabtRow>
        {showMore && (
          <CardWrapper>
            <FixedHeightRow>
              <TYPE.body fontSize={14} fontWeight={500}>
                <Trans>Your pool tokens</Trans>:
              </TYPE.body>
              <Text fontSize={14} fontWeight={500}>
                {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
              </Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <RowFixed>
                <TYPE.body fontSize={14} fontWeight={500}>
                  Pooled {currency0.symbol}
                </TYPE.body>
              </RowFixed>
              {token0Deposited ? (
                <RowFixed>
                  <Text fontSize={14} fontWeight={500} marginLeft={'6px'}>
                    {token0Deposited?.toSignificant(6)}
                  </Text>
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <RowFixed>
                <TYPE.body fontSize={14} fontWeight={500}>
                  Pooled {currency1.symbol}
                </TYPE.body>
              </RowFixed>
              {token1Deposited ? (
                <RowFixed>
                  <Text fontSize={14} fontWeight={500} marginLeft={'6px'}>
                    {token1Deposited?.toSignificant(6)}
                  </Text>
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>
            <FixedHeightRow>
              <TYPE.body fontSize={14} fontWeight={500}>
                Your pool share:
              </TYPE.body>
              <Text fontSize={14} fontWeight={500}>
                {poolTokenPercentage ? poolTokenPercentage.toFixed(2) + '%' : '-'}
              </Text>
            </FixedHeightRow>

            <RowBetween marginTop="16px">
              <ButtonPrimary
                size="8px"
                as={Link}
                to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                width="48%"
                style={{ fontSize: '14px', borderRadius: '12px' }}
              >
                <Trans>Add</Trans>
              </ButtonPrimary>
              <ButtonPrimary
                size="8px"
                as={Link}
                width="48%"
                to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
                style={{ fontSize: '14px', borderRadius: '12px' }}
              >
                <Trans>Remove</Trans>
              </ButtonPrimary>
            </RowBetween>
          </CardWrapper>
        )}
      </AutoColumn>
    </HoverCard>
  )
}
