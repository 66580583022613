import React from 'react'
import styled from 'styled-components'
import Menu from './ChainSwitchMenu';


interface SwithProps {
  className?: string
}
const AccountLink: React.FC<SwithProps> = props => {

  return (
    <StyledButton className={props?.className}>
      <Menu />
    </StyledButton>
  )
}


const StyledButton = styled.div`
  position: relative;
  padding: 11px 16px;
  // background: linear-gradient(90.45deg, #759FFF 0.39%, #93CEF5 99.61%);
  background: ${({ theme }) => theme.colors.bgSecondary};
  border-radius: 16px;
  margin-right: 16px;
`
export default AccountLink
