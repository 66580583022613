import { Trade, TradeType } from '@pancakeswap-libs/sdk'
import React, { useMemo, useState } from 'react'
import { Text } from 'rebass'
import styled from 'styled-components'
import { Field } from '../../state/swap/actions'
import { TYPE } from '../Shared'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import { t } from '@lingui/macro'
import QuestionHelper from '../QuestionHelper'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { SwapCallbackError } from './styleds'
import TradePrice from './TradePrice'

export const WrapperRaw = styled(RowBetween)`
  padding: 0;
`

export default function SwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  swapErrorMessage,
  disabledConfirm
}: {
  trade: Trade
  allowedSlippage: number
  onConfirm: () => void
  swapErrorMessage: string | undefined
  disabledConfirm: boolean
}) {
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    allowedSlippage,
    trade
  ])
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)

  return (
    <>
      <AutoColumn gap="0px" style={{ marginTop: '24px' }}>
        <RowBetween align="top">
          <TYPE.main fontWeight={400} fontSize={14}>
            Rate
          </TYPE.main>
          <TradePrice price={trade?.executionPrice} showInverted={showInverted} setShowInverted={setShowInverted} />
        </RowBetween>

        <WrapperRaw>
          <RowFixed marginTop='8px' lineHeight='20px'>
            <TYPE.main fontSize={14} fontWeight={400}>
              {trade.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum sold'}
            </TYPE.main>
            <QuestionHelper text={t`Your transaction will revert if there is a large, unfavorable price movement before it is confirmed`} />
          </RowFixed>
          <RowFixed marginTop='8px' lineHeight='20px'>
            <TYPE.black fontSize={14}>
              {trade.tradeType === TradeType.EXACT_INPUT
                ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
            </TYPE.black>
            <TYPE.main fontSize={14} marginLeft={'4px'}>
              {trade.tradeType === TradeType.EXACT_INPUT
                ? trade.outputAmount.currency.symbol
                : trade.inputAmount.currency.symbol}
            </TYPE.main>
          </RowFixed>
        </WrapperRaw>
        <WrapperRaw>
          <RowFixed marginTop="8px" lineHeight="20px">
            <TYPE.main fontSize={14} fontWeight={400}>
              Price Impact
            </TYPE.main>
            <QuestionHelper text={t`The difference between the market price and your price due to trade size`} />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </WrapperRaw>
        <WrapperRaw>
          <RowFixed marginTop="8px" lineHeight="20px">
            <TYPE.main fontSize={14} fontWeight={400}>
              Trade Fee
            </TYPE.main>
            <QuestionHelper text={t`The cost to complete the transaction`} />
          </RowFixed>
          <RowFixed marginTop="8px" lineHeight="20px">
            <TYPE.black fontSize={14}>{realizedLPFee ? realizedLPFee?.toSignificant(6) : '-'}</TYPE.black>
            <TYPE.main marginLeft={'4px'}>{realizedLPFee ? trade.inputAmount.currency.symbol : ''}</TYPE.main>
          </RowFixed>
        </WrapperRaw>
      </AutoColumn>

      <AutoRow>
        <ButtonError
          onClick={onConfirm}
          disabled={disabledConfirm}
          error={severity > 2}
          style={{ margin: '20px 0 0 0' }}
          id="confirm-swap-or-send"
        >
          <Text fontSize={16} fontWeight={500}>
            {severity > 2 ? 'Swap Anyway' : 'Confirm Swap'}
          </Text>
        </ButtonError>

        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
