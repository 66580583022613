import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 396px;
  width: 100%;
  background: ${({ theme }) => theme.colors.bgSecondary};
  border-radius: 20px;
  padding: 32px;
  // box-shadow: 0px 8px 40px rgba(138, 167, 177, 0.08);

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 32px 16px;
    border-radius: 24px;
  `};
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
