export const SUPPORTED_LOCALES = ['en-US'] as const
export type SupportedLocale = typeof SUPPORTED_LOCALES[number]

export const DEFAULT_LOCALE: SupportedLocale = 'en-US'

export const LOCALE_LABEL: { [locale in SupportedLocale]: string } = {
  'en-US': 'English'
  // 'ko-KR': '한국어',
  // 'ja-JP': '日本語',
  // 'zh-CN': '简体中文'
}

export const LOCALE_ACRONYM_LABEL: { [locale in SupportedLocale]: string } = {
  'en-US': 'EN'
  // 'ko-KR': 'KO',
  // 'ja-JP': 'JA',
  // 'zh-CN': 'CN'
}
