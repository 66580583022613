import { Currency, CurrencyAmount, Fraction, Percent } from '@pancakeswap-libs/sdk'
import React from 'react'
import { Text } from 'rebass'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { ButtonPrimary } from '../../components/Button'
import { RowBetween, RowFixed } from '../../components/Row'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../components/Shared'
import { PoolPriceBar } from './PoolPriceBar'
import { Trans } from '@lingui/macro'
export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd
}: {
  noLiquidity?: boolean
  price?: Fraction
  currencies: { [field in Field]?: Currency }
  parsedAmounts: { [field in Field]?: CurrencyAmount }
  poolTokenPercentage?: Percent
  onAdd: () => void
}) {
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  return (
    <>
      <RowBetween>
        <TYPE.body fontSize={14}>{currencies[Field.CURRENCY_A]?.symbol} <Trans>Deposited</Trans></TYPE.body>
        <RowFixed>
          <TYPE.black fontSize={14}>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</TYPE.black>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <TYPE.body fontSize={14}>{currencies[Field.CURRENCY_B]?.symbol} <Trans>Deposited</Trans></TYPE.body>
        <RowFixed>
          <TYPE.black fontSize={14}>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</TYPE.black>
        </RowFixed>
      </RowBetween>
      <PoolPriceBar
        currencies={currencies}
        poolTokenPercentage={poolTokenPercentage}
        noLiquidity={noLiquidity}
        price={price}
      />
      <TYPE.body fontSize={12} textAlign="left" padding={'12px 0 0 0'}>
        <Trans>{`Output is estimated. If the price changes by more than ${allowedSlippage /
          100}% your transaction will revert`}</Trans>
      </TYPE.body>
      <ButtonPrimary style={{ margin: '12px 0 0 0' }} onClick={onAdd}>
        <Text fontWeight={500} fontSize={16}>
          {noLiquidity ? 'Create Pool & Supply' : 'Confirm Supply'}
        </Text>
      </ButtonPrimary>
    </>
  )
}
