import base from './base'

export default {
  ...base,
  colors: {
    ...base.colors,
    shadow1: 'transparent',
    textPrimary: '#FFFFFF',
    textTheme: '#F0E967',
    textSecondary: '#C6CAD3',
    textTertiary: '#80889B',
    textFall: '#E64061',
    textRise: '#31BD8F',

    // backgrounds / greys
    bgTheme: '#4856F0',
    bgPrimary: '#1A1D24',
    bgSecondary: '#21252E',
    bgTertiary: '#2C313D',
    bgHover: '#373E4D',
    bgFooter: '#1A1D24',
    bgHover1: '#373E4D',

    modalBG: 'rgba(9, 11, 16, 0.91)',
    advancedBG: 'rgba(0,0,0,0.6)',

    //primary colors
    primary1: '#4856F0',
    primary2: '#4856F0',

    // color text
    primaryText1: '#FFFFFF',
    buttonText: '#ffffff',
    buttonHover: '#F0E967',

    // secondary colors
    secondary1: '#C6CAD3',
    bg: '#1A1D24',
    cardBg: '#1A1D24',
    cardBg2: '#21252E',
    cardBg3: '#21252E',
    cardBg4: '#2C313D',
    cardBg5: '#2C313D',
    primary: '#fff',
    secondary: '#C6CAD3',
    normal: '#fff',
    inputBg: '#21252E',
    strokColor: '#383E50',
    navBg: '#21252E',
    icon: '#C6CAD3',

    btnColor: '#4856F',
    btnText: '#fff',
    //
    warn: '#FFA033',
    success: '#19A680',
    error: '#F5566F',
    separator: '#383E50',
    bgColor: '#1A1D24'
  }
}
