import { ChainId, JSBI, Percent, Token, WOKB } from '@pancakeswap-libs/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

// import { bsc, fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'
import { injected, okbInjected } from '../connectors'
// TODO
export const ROUTER_ADDRESS = '0xCE6C16dCC855c1BC54Ae871cF4E3107286e20cfD'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

// export const HUSD = new Token(
//   ChainId.MAINNET,
//   '0x1e4a5963abfd975d8c9021ce480b42188849d41d',
//   8,
//   'HUSD',
//   'Heco-Peg HUSD Token'
// )
export const USDT = new Token(
  ChainId.MAINNET,
  '0x1e4a5963abfd975d8c9021ce480b42188849d41d',
  6,
  'USDC',
  'USDC Token'
)
export const OKB = new Token(
  ChainId.MAINNET,
  '0xe538905cf8410324e03a5a23c1c177a474d59b2b',
  18,
  'OKB',
  'OKB Token'
)

export const ZEST = new Token(ChainId.MAINNET, '0x0000000000000000000000000000000000000000', 18, 'ZEST', 'ZEST')

// export const U2 = new Token(ChainId.MAINNET, '0xA1d887987Be4b3C807B86240fFA975Aa6593C1E0', 18, 'cUSDT', 'cUSDT')
// export const H2 = new Token(ChainId.MAINNET, '0xE982f461BE44c1d9116e115ed3A6538F44920446', 8, 'cHUSD', 'cHUSD')

const WOKB_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WOKB[ChainId.MAINNET]],
  // [ChainId.TESTNET]: [WOKB[ChainId.TESTNET]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WOKB_ONLY,
  [ChainId.MAINNET]: [...WOKB_ONLY[ChainId.MAINNET], USDT, ZEST]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    //[OKB.address]: [WOKB[ChainId.MAINNET]]
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WOKB_ONLY,
  [ChainId.MAINNET]: [...WOKB_ONLY[ChainId.MAINNET], USDT]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WOKB_ONLY,
  [ChainId.MAINNET]: [...WOKB_ONLY[ChainId.MAINNET], USDT]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x0000000000000000000000000000000000000000', 18, 'ZEST', 'ZEST SHRIMP Token'),
      new Token(ChainId.MAINNET, '0xe538905cf8410324e03a5a23c1c177a474d59b2b', 18, 'OKB', 'OKB')
    ],
    //[HUSD, USDT]
  ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  // INJECTED: {
  //   connector: injected,
  //   name: 'Injected',
  //   iconName: 'arrow-right.svg',
  //   description: 'Injected web3 provider.',
  //   href: null,
  //   color: '#010101',
  //   primary: true
  // },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
    mobile: true
  },
  OKXWALLET: {
    connector: okbInjected,
    name: 'OKXWallet',
    iconName: 'okbchain.svg',
    description: 'OKX Wallet',
    href: null,
    color: '#000000',
    mobile: true
  },
  // WALLET_CONNECT: {
  //   connector: walletconnect,
  //   name: 'WalletConnect',
  //   iconName: 'walletConnectIcon.svg',
  //   description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
  //   href: null,
  //   color: '#4196FC',
  //   mobile: true
  // },
  // WALLET_LINK: {
  //   connector: walletlink,
  //   name: 'Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Use Coinbase Wallet app on mobile device',
  //   href: null,
  //   color: '#315CF5'
  // },
  // COINBASE_LINK: {
  //   name: 'Open in Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Open in Coinbase Wallet app.',
  //   href: 'https://go.cb-w.com/mtUDhEZPy1',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true
  // },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true
  // }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much OKB so they end up with <.01
export const MIN_OKB: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 OKB
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))

export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')
