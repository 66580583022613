// @ts-nocheck
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import Web3 from 'web3'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { darken } from 'polished'
import { Activity } from 'react-feather'
import { NetworkContextName } from '../../constants'
import useENSName from '../../hooks/useENSName'
import { TYPE } from '../Shared/Type'

import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { useWalletModalToggle } from '../../state/application/hooks'
import WalletModal from '../WalletModal'
import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'
import { RowBetween } from '../Row'
import Loader from '../Loader'

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;
  :focus {
    outline: none;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.colors.error};
  border: 1px solid ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.bg};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.colors.error)};
  }
`

const Web3StatusConnect = styled(Web3StatusGeneric) <{ faded?: boolean }>`
  ${({ faded }) =>
    faded &&
    css`
      border: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      background-color: ${({ theme }) => theme.colors.bgTheme};

      > * {
        color: ${({ theme }) => theme.colors.btnText} !important;
      }
    `}
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  background-color: ${({ pending, theme }) => (pending ? theme.colors.primary1 : theme.colors.bgTheme)};
  border: 1px solid ${({ pending, theme }) => (pending ? theme.colors.primary1 : theme.colors.bgTheme)};
  color: ${({ pending, theme }) => (pending ? theme.colors.white : theme.colors.white)};
  font-weight: 500;
  margin: 0.1875rem;
  :hover,
  :focus {
    background-color: ${({ pending, theme }) => (pending ? darken(0.05, theme.colors.primary1) : theme.colors.bgTheme)};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall<{ pending?: boolean }>`
    border: 0;
    color : ${({ pending, theme }) => (pending ? theme.colors.white : theme.colors.textSecondary)};
    background-color: ${({ pending, theme }) => (pending ? theme.colors.bgFooter : theme.colors.separator)};
    > * { 
      background-color: ${({ theme }) => theme.colors.separator} !important;
     }
  `};
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  font-size: 14px;
  width: fit-content;
  font-weight: 500;
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

let reject = false

function Web3StatusInner() {
  const { account, error } = useWeb3React()

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)

  const hasPendingTransactions = !!pending.length

  const toggleWalletModal = useWalletModalToggle()

  if (account) {
    return (
      <Web3StatusConnected id="web3-status-connected" onClick={toggleWalletModal} pending={hasPendingTransactions}>
        {hasPendingTransactions ? (
          <RowBetween>
            <Text>{pending?.length} Pending</Text> <Loader stroke="white" />
          </RowBetween>
        ) : (
          <>
            <Text>{ENSName || shortenAddress(account)}</Text>
          </>
        )}
      </Web3StatusConnected>
    )
  } else if (error) {
    const isErrorChain = error instanceof UnsupportedChainIdError
    if (isErrorChain) {
      try {
        if (window.ethereum && !reject) {
          const web3Instance = new Web3(window.ethereum)
          web3Instance?.currentProvider.send({
            "method": "wallet_addEthereumChain",
            "params": [
              {
                "chainId": "0xc4",
                "chainName": "X Layer",
                "rpcUrls": ["https://rpc.x1.tech"],
                "nativeCurrency": {
                  "name": "OKB",
                  "symbol": "OKB",
                  "decimals": 18
                },
                "blockExplorerUrls": ["https://www.oklink.com/cn/xlayer"]
              }
            ]
          }, function (err: any, result: any) {
            reject = true
          });
        }
      } catch (err) {}
    }
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <TYPE.white>{ isErrorChain ? 'Wrong Network' : 'Error'}</TYPE.white>
      </Web3StatusError>
    )
  } else {
    return (
      <Web3StatusConnect id="connect-wallet" onClick={toggleWalletModal} faded={!account}>
        <TYPE.darkGray>Connect to a wallet</TYPE.darkGray>
      </Web3StatusConnect>
    )
  }
}

export default function Web3Status() {
  const { active, account } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)
  const confirmed = sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash)

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <>
      <Web3StatusInner />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
