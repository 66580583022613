import base from './base'

export default {
  ...base,
  colors: {
    ...base.colors,
    shadow1: '#000',
    textPrimary: '#1B2333',
    textTheme: '#5F47FF',
    textSecondary: '#657AA3',
    textTertiary: '#9BA8C2',
    textFall: '#E64061',
    textRise: '#23AD7F',

    // backgrounds / greys
    bgTheme: '#5444C7',
    bgPrimary: '#FAFCFF',
    bgSecondary: '#FFF',
    bgTertiary: '#F0F3FA',
    bgHover: '#F4F5FD',
    bgFooter: '#111214',
    bgHover1: '#EDEFFA',

    //specialty colors
    modalBG: 'rgba(0,0,0,.425)',
    advancedBG: 'rgba(0,0,0,0.1)',

    //primary colors
    primary1: '#6E84FB',
    primary4: '#376bad70',
    primary5: '#153d6f70',

    // color text
    primaryText1: '#6da8ff',
    buttonText: '#ffffff',
    buttonHover: '#5D4BD1',

    // secondary colors
    secondary1: '#24c7d6',
    bg: '#2F2E2D',
    cardBg: '#3D3D3D',
    cardBg2: '#191326',
    cardBg3: '#51535E',
    cardBg4: '#2F2E2D',
    cardBg5: '#332525',
    primary: '#EE9715',
    secondary: '#FFF',
    normal: '#FFF',
    inputBg: '#2F2E2D',
    strokColor: '#2F2E2D',
    navBg: '#2F2E2D',
    icon: '#C4C4C4',

    btnColor: '#5F4CE0',
    success: '#19A680',
    error: '#F5566F',
    separator: '#EAECF5',
    bgColor: '#FAFCFF'
  }
}
