import React from 'react'
import { Price } from '@pancakeswap-libs/sdk'
import { useContext } from 'react'
import { TYPE } from '../Shared'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { AutoColumn } from '../Column'
import { RowFlat, RowFixed } from '../Row'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const theme = useContext(ThemeContext)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)

  return (
    <Text
      fontWeight={500}
      fontSize={14}
      color={theme.colors.textSecondary}
      style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
    >
      {show ? (
        <>
          <AutoColumn>
            <RowFlat style={{ lineHeight: '20px' }}>
              <RowFixed>
                <TYPE.body fontSize={14} fontWeight={400}>
                  1 {price?.baseCurrency?.symbol} =
                </TYPE.body>
                <TYPE.black fontSize={14} fontWeight={400} paddingX="3px">
                  {price?.toSignificant(6)}
                </TYPE.black>
                <TYPE.body fontSize={14} fontWeight={400}>
                  {price?.quoteCurrency?.symbol}
                </TYPE.body>
              </RowFixed>
            </RowFlat>
            <RowFlat style={{ lineHeight: '20px', marginTop: '8px' }}>
              <RowFixed>
                <TYPE.body fontSize={14} fontWeight={400}>
                  1 {price?.quoteCurrency?.symbol} =
                </TYPE.body>
                <TYPE.black fontSize={14} fontWeight={400} paddingX="3px">
                  {price?.invert()?.toSignificant(6)}
                </TYPE.black>
                <TYPE.body fontSize={14} fontWeight={400}>
                  {price?.baseCurrency?.symbol}
                </TYPE.body>
              </RowFixed>
            </RowFlat>
          </AutoColumn>
        </>
      ) : (
        '-'
      )}
    </Text>
  )
}
