import React, { useMemo } from 'react'
import { Pair } from '@pancakeswap-libs/sdk'
import PageHeader from '../../components/PageHeader'

import FullPositionCard from '../../components/PositionCard'
// import { useUserHasLiquidityInAllTokens } from '../../data/V1'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { StyledInternalLink, TYPE } from '../../components/Shared'
import { LightCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'

import { useActiveWeb3React } from '../../hooks'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import AppBody from '../AppBody'
import { Dots } from '../../components/swap/styleds'
import { t, Trans } from '@lingui/macro'
import { PoolTabs } from '../../components/NavigationTabs'
import { useLpAddress } from '../../state/multicall/hooks'

// import { useV1FactoryContract } from '../../hooks/useContract'
// import { useSingleContractMultipleData } from '../../state/multicall/hooks'

export default function Pool() {
  const { account } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const lpAddress = useLpAddress(trackedTokenPairs)

  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens, i) => {
      const address = lpAddress?.[tokens[0]?.address?.toLowerCase() + '-' + tokens[1]?.address?.toLowerCase()]?.lpAddress || ''
      return ({ liquidityToken: toV2LiquidityToken(tokens, address), tokens })
    }),
    [trackedTokenPairs, lpAddress]
  )

  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )
  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () => {
      return tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken['address']]?.greaterThan('0')
      )
    },
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  // fetchingV2PairBalances ||
  const v2IsLoading = v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const hasV1Liquidity = false // useUserHasLiquidityInAllTokens()

  return (
    <>
      <AppBody>
        <PageHeader active={'pool'} />
        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="32px" style={{ width: '100%' }}>
            <PoolTabs active="your" />

            {!account ? (
              <LightCard style={{ display: 'flex', padding: "82px 0", justifyContent: 'center' }}>
                <TYPE.body textAlign="center">Connect to a wallet to view your liquidity.</TYPE.body>
              </LightCard>
            ) : v2IsLoading ? (
              <LightCard style={{ display: 'flex', padding: "82px 0", justifyContent: 'center' }}>
                <TYPE.body textAlign="center">
                  <Dots>Loading</Dots>
                </TYPE.body>
              </LightCard>
            ) : allV2PairsWithLiquidity?.length > 0 ? (
              <>
                {allV2PairsWithLiquidity.map(v2Pair => (
                  <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                ))}
              </>
            ) : (
              <LightCard style={{ display: 'flex', padding: "82px 0", justifyContent: 'center' }}>
                <TYPE.body textAlign="center" style={{ flex: 'none' }}>
                  <Trans>No liquidity found</Trans>
                </TYPE.body>
              </LightCard>
            )}

            <div>
              <TYPE.body textAlign="center" fontSize={14} style={{ padding: '.5rem 0 .5rem 0' }}>
                {hasV1Liquidity ? 'Uniswap V1 liquidity found!' : t`Don't see a pool you joined?`}{' '}
                <StyledInternalLink id="import-pool-link" to={hasV1Liquidity ? '/migrate/v1' : '/find'}>
                  {hasV1Liquidity ? t`Migrate now` : t`Import it`}
                </StyledInternalLink>
              </TYPE.body>
            </div>
          </AutoColumn>
        </AutoColumn>
      </AppBody>
    </>
  )
}
