export const contractAddresses = {
  sushi: {
    196: '0x8fB1D8C8085663bF574a5c44313CC50e9719FC22',
    // 66: '0x748def2e7fbb37111761aa78260b0ce71e41d4ca'
  },
  syrup: {
    196: '0xB5cB3f21A4C241f572857c2f74e197B5D751036E',
    // 66: '0x8377D9A4069e92653906a061Fa66270476c1Dc9e'
  },
  masterChef: {
    196: '0x203f1800EE213c0284c0f2A723ed4228633125cD',
    // 66: '0xa02fF30986211B7ca571AcAE5AD4D25ab1e58426'
  },
  sousChef: {
    196: '0x9b7d4fd169A38e33011B96cFA36CFfB70e3e8236',
    // 66: '0xd5B771014369Fa718Dca73b101793E56F1B43E6d'
  },
  weth: {
    196: '0xe538905cf8410324e03a5a23c1c177a474d59b2b',
    // 66: '0xe538905cf8410324e03a5a23c1c177a474d59b2b'
  },
  airdrop: {
    196: '0xA94213C0c9DCadADe05205Ca500d60cd001B8798',
    // 66: '0x448Ec9549cc7C7D4cF0E8dfb1fA0E56bFA9086C5'
  },
  trading: {
    196: '0xCE6C16dCC855c1BC54Ae871cF4E3107286e20cfD',
    // 66: '0x648C6B73825bAe6e1ac6D800a0cD751A6De8002D'
  }
}

const multicall = {
  196: '0x2be23302B64f8240eeD50f57B0202Af9ae65C285',
  // 66: '0x2be23302B64f8240eeD50f57B0202Af9ae65C285'
}
const chainId = 196

interface AddressProps {
  196: string, // Address of the contract
}
export const getAddress = (obj: AddressProps) => {
  return obj[chainId] ? obj[chainId] : obj
}

export const getMasterChefAddress = () => {
  return getAddress(contractAddresses.masterChef)
}

export const getMulticallAddress = () => {
  return multicall[chainId]
}

export const getCoCoAddress = () => {
  return getAddress(contractAddresses.sushi)
}

export const getTradingAddress = () => {
  return getAddress(contractAddresses.trading)
}
