import { Token, Currency, Pair } from '@pancakeswap-libs/sdk'
import React, { useContext } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { AutoColumn } from '../../components/Column'
import { AutoRow, RowFlat, RowFixed } from '../../components/Row'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../components/Shared'

export function PoolPriceBar({
  tokens,
  currencys,
  pair
}: {
  tokens: { [field in Field]?: Token }
  currencys: { [field in Field]?: Currency }
  pair?: Pair
}) {
  const theme = useContext(ThemeContext)
  const show = Boolean(pair)

  return (
    <AutoColumn>
      <AutoRow justify="space-between" gap="0px" align="flex-start">
        <AutoColumn>
          <RowFlat style={{ justifyContent: 'space-between', minHeight: '20px' }}>
            <Text fontWeight={500} fontSize={14} color={theme.colors.textSecondary}>
              Rate
            </Text>
            {show ? (
              <RowFixed>
                <TYPE.body fontSize={14} fontWeight={400}>
                  1 {currencys[Field.CURRENCY_A]?.symbol} =
                </TYPE.body>
                <TYPE.black fontSize={14} fontWeight={400} paddingX="3px">
                  {tokens[Field.CURRENCY_A] ? pair?.priceOf(tokens[Field.CURRENCY_A]!)?.toSignificant(6) : '-'}
                </TYPE.black>
                <TYPE.body fontSize={14} fontWeight={400}>
                  {currencys[Field.CURRENCY_B]?.symbol}
                </TYPE.body>
              </RowFixed>
            ) : null}
          </RowFlat>
          <RowFlat style={{ justifyContent: 'flex-end', marginTop: '8px', minHeight: '20px' }}>
            {show ? (
              <RowFixed>
                <TYPE.body fontSize={14} fontWeight={400}>
                  1 {currencys[Field.CURRENCY_B]?.symbol} =
                </TYPE.body>
                <TYPE.black fontSize={14} fontWeight={400} paddingX="3px">
                  {tokens[Field.CURRENCY_B] ? pair?.priceOf(tokens[Field.CURRENCY_B]!)?.toSignificant(6) : '-'}
                </TYPE.black>
                <TYPE.body fontSize={14} fontWeight={400}>
                  {currencys[Field.CURRENCY_A]?.symbol}
                </TYPE.body>
              </RowFixed>
            ) : null}
          </RowFlat>
        </AutoColumn>
      </AutoRow>
    </AutoColumn>
  )
}
