import React, { useCallback, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'
// import CountUp from 'react-countup'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { ZEST } from '../../constants'
// import LngSwithForWeb from './LngSwithForWeb'
import ChainSwitch from './ChainSwitch'
import Nav from './Nav'
import Logo from './Logo'
import { Trans, t } from '@lingui/macro'
// import menuIcon from '../../assets/images/menu.png'
// import { useTradingContract } from '../../hooks/useContract'
import { useTotalReward } from '../../hooks/useTotalReward'
// import { TransactionResponse } from '@ethersproject/providers'
// import { useTransactionAdder } from '../../state/transactions/hooks'
import { IconFont } from '../IconFont'
import MobileModal from '../MobileModal'
import Web3Status from '../Web3Status'
// import { getFloatDigit } from '../../utils/formatBalance'

const HeaderFrame = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 24px;
  margin: 0 auto;
  box-sizing: border-box;
  background:  ${({ theme }) => theme.colors.bgColor}
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: calc(100%);
    padding: 0 16px;
    position: relative;
  `};
`
const Menu = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: block;
    img {
      width: 100%;
      display: block;
    }
  `};
`
const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 320px) {
    width: 20%;
  }
`

const HeaderControlsMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  `};
`

const StyledTopBarInner = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 24px;
  box-sizing: border-box;
  padding: 11px 0;
  @media (max-width: 850px) {
    min-width: auto;
  }
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.colors.bgPrimary : theme.colors.bgTertiary)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  color: ${props => props.theme.colors.textPrimary}

  :focus {
    border: 1px solid blue;
  }
`
const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const PageTitle = styled.div`
  position: absolute;
  top: 88px;
  left: 50%;
  display: block;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  transform: translateX(-50%);
  color: ${({ theme }) => theme.colors.textPrimary};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    top: 70px;
    width: 100%;
    font-size: 18px;
    line-height: 24px;
  `};
`
// const Rewardbox = styled.div`
//   position: absolute;
//   display: flex;
//   height: 60px;
//   top: 144px;
//   left: 50%;
//   width: 396px;
//   align-items: center;
//   border-radius: 24px;
//   transform: translateX(-50%);
//   background: linear-gradient(90.02deg, #4339EE 0.02%, #4583DF 99.98%);

//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     width: calc(100vw - 32px);
//     top: 110px;
//     height: 48px;
//     border-radius: 16px;
//   `};
// `

// const RewardLabel = styled.div`
//   font-size: 16px;
//   line-height: 24px;
//   margin-left: 32px;
//   font-weight: 500;
//   color: ${({ theme }) => theme.colors.textPrimary};

//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     font-size: 14px;
//     margin-left: 16px;
//   `};
// `

// const RewardValue = styled.div`
//   font-weight: 500;
//   font-size: 20px;
//   line-height: 24px;
//   margin-left: 8px;
//   color: ${({ theme }) => theme.colors.textPrimary};

//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     font-size: 18px;
//   `};
// `

// const RewardBtn = styled.button`
//   cursor: pointer;
//   position: absolute;
//   right: 16px;
//   width: 76px;
//   height: 34px;
//   font-size: 14px;
//   line-height: 34px;
//   text-align: center;
//   border-radius: 12px;
//   border: none;
//   padding: 0;
//   color: ${({ theme }) => theme.colors.textPrimary};
//   background: ${({ theme }) => theme.colors.bg};

//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     right: 8px;
//     width: 70px;
//     height: 36px;
//   `};

//   &:disabled {
//     cursor: auto;
//   }
// `

const LangIcon = styled(IconFont)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.textSecondary};
  > * {
    width: 24px;
    height: 24px;
  }
`

export default function Header() {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  // const addTransaction = useTransactionAdder()
  // const [hash, setHash] = useState<string | undefined>()
  // const [attempting, setAttempting] = useState(false)

  const stakingInfo = useTotalReward()

  const userCoCoBalance = useTokenBalance(account ?? undefined, ZEST)
  const [mobileMenu, setMobileMenu] = useState(false)
  const handlePresentMobileMenu = useCallback(() => {
    setMobileMenu(true)
  }, [setMobileMenu])
  const handleDismissMobileMenu = useCallback(() => {
    setMobileMenu(false)
  }, [setMobileMenu])

  // const stakingContract = useTradingContract(true)

  // async function onClaimReward() {
  //   if (stakingContract && stakingInfo?.totalRewards) {
  //     setAttempting(true)
  //     await stakingContract
  //       .takerWithdraw()
  //       .then((response: TransactionResponse) => {
  //         addTransaction(response, { summary: `Claim accumulated ZEST rewards` })
  //         //setHash(response.hash)
  //         setAttempting(false)
  //       })
  //       .catch((error: any) => {
  //         setAttempting(false)
  //         console.log(error)
  //       })
  //   }
  // }

  let error: string | undefined
  if (!account) {
    error = t`Connect wallet`
  }
  if (stakingInfo?.totalRewards?.lessThan('0')) {
    error = error ?? t`Enter an amount`
  }

  return (
    <HeaderFrame id="HeaderFrame">
      <StyledTopBarInner>
        <HeaderElement>
          <Title href="/">
            <Logo />
          </Title>
          {!isMobile && <Nav />}
        </HeaderElement>
        {isMobile && (
          <>
            <HeaderControlsMobile>
              <ChainSwitch />
              <Web3Status />
            </HeaderControlsMobile>
          </>
        )}

        <PageTitle><Trans>Trade is mining free coco, No fees</Trans></PageTitle>
        {/* <Rewardbox>
          <RewardLabel><Trans>挖矿奖励</Trans></RewardLabel>
          <RewardValue>
            <CountUp
              start={0}
              end={Number(stakingInfo?.totalRewards?.toSignificant() ?? 0)}
              decimals={getFloatDigit(stakingInfo?.totalRewards?.toSignificant() ?? '')}
              separator=","
            />{' '}
            ZEST
          </RewardValue>
          <RewardBtn disabled={!!error || attempting} onClick={onClaimReward}>
            <Trans>Claim</Trans>
          </RewardBtn>
        </Rewardbox> */}

        {!isMobile && (
          <HeaderControls>
            <HeaderElement>
              <ChainSwitch />
              <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
                {account && userCoCoBalance ? (
                  <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                    {userCoCoBalance?.toSignificant(4)} ZEST
                  </BalanceText>
                ) : null}
                <Web3Status />
              </AccountElement>

              {/* <LngSwithForWeb /> */}
            </HeaderElement>
          </HeaderControls>
        )}

        <MobileModal onDismiss={handleDismissMobileMenu} visible={mobileMenu} />

        <Menu onClick={handlePresentMobileMenu}>
          {/* <img src={menuIcon} alt="menu" /> */}

          <LangIcon type="icon-ellipsis" />
        </Menu>
      </StyledTopBarInner>
    </HeaderFrame>
  )
}
