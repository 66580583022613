import { Currency, Percent } from '@pancakeswap-libs/sdk'
import React, { useContext } from 'react'
import { Text } from 'rebass'
import { Trans } from '@lingui/macro'
import { ThemeContext } from 'styled-components'
import { AutoColumn } from '../../components/Column'
import { AutoRow, RowFlat, RowFixed } from '../../components/Row'
import { ONE_BIPS } from '../../constants'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../components/Shared'

export function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price
}: {
  currencies: { [field in Field]?: Currency }
  noLiquidity?: boolean
  poolTokenPercentage?: Percent
  price?: Percent
}) {
  const theme = useContext(ThemeContext)
  const show = Boolean(currencies[Field.CURRENCY_B] && currencies[Field.CURRENCY_A])

  return (
    <AutoColumn>
      {show ? (
        <AutoRow justify="space-between" gap="0px" align="flex-start">
          <AutoColumn>
            <RowFlat style={{ justifyContent: 'space-between', minHeight: '20px' }}>
              <Text fontWeight={500} fontSize={14} color={theme.colors.textSecondary}>
                <Trans>Rate</Trans>
              </Text>
              <RowFixed>
                <TYPE.body fontSize={14} fontWeight={400}>
                  1 {currencies[Field.CURRENCY_A]?.symbol} =
                </TYPE.body>
                <TYPE.black fontSize={14} fontWeight={400} paddingX="3px">
                  {price?.toSignificant(6)}
                </TYPE.black>
                <TYPE.body fontSize={14} fontWeight={400}>
                  {currencies[Field.CURRENCY_B]?.symbol}
                </TYPE.body>
              </RowFixed>
            </RowFlat>
            <RowFlat style={{ justifyContent: 'flex-end', marginTop: '8px', minHeight: '20px' }}>
              <RowFixed>
                <TYPE.body fontSize={14} fontWeight={400}>
                  1 {currencies[Field.CURRENCY_B]?.symbol} =
                </TYPE.body>
                <TYPE.black fontSize={14} fontWeight={400} paddingX="3px">
                  {price?.invert()?.toSignificant(6)}
                </TYPE.black>
                <TYPE.body fontSize={14} fontWeight={400}>
                  {currencies[Field.CURRENCY_A]?.symbol}
                </TYPE.body>
              </RowFixed>
            </RowFlat>
          </AutoColumn>
        </AutoRow>
      ) : null}
      <AutoRow justify="space-between" gap="0px" align="flex-start" style={{ marginTop: '8px' }}>
        <Text fontWeight={500} fontSize={14} color={theme.colors.textSecondary}>
          <Trans>Share of Pool</Trans>
        </Text>
        <TYPE.black fontSize={14}>
          {noLiquidity && price
            ? '100'
            : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
          %
        </TYPE.black>
      </AutoRow>
    </AutoColumn>
  )
}
