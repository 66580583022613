import { Text } from 'rebass'
import styled from 'styled-components'
import { AutoColumn } from '../../components/Column'

export const Wrapper = styled.div`
  position: relative;
`
export const PoolWrapper = styled(AutoColumn)`
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  border-radius: 16px;
`

export const ClickableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  color: ${({ theme }) => theme.colors.primary1};
`
export const MaxButton = styled.button<{ width: string }>`
  flex: 1;
  padding: 10px 24px;
  min-width: 3.9375rem;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  border: 1px solid ${({ theme }) => theme.colors.bgTertiary};
  border-radius: 0.5rem;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.textSecondary};
  :hover {
    border: 1px solid ${({ theme }) => theme.colors.primary1};
    background-color: ${({ theme }) => theme.colors.bgSecondary};
    color: ${({ theme }) => theme.colors.primary1};
  }
  :focus {
    background-color: ${({ theme }) => theme.colors.bgSecondary};
    border: 1px solid ${({ theme }) => theme.colors.primary1};
    color: ${({ theme }) => theme.colors.primary1};
    outline: none;
  }
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`
