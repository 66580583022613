import { CurrencyAmount, OKBER, JSBI } from '@pancakeswap-libs/sdk'
import { MIN_OKB } from '../constants'

/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 */
export function maxAmountSpend(currencyAmount?: CurrencyAmount): CurrencyAmount | undefined {
  if (!currencyAmount) return undefined
  if (currencyAmount.currency === OKBER) {
    if (JSBI.greaterThan(currencyAmount.raw, MIN_OKB)) {
      return CurrencyAmount.ether(JSBI.subtract(currencyAmount.raw, MIN_OKB))
    } else {
      return CurrencyAmount.ether(JSBI.BigInt(0))
    }
  }
  return currencyAmount
}
